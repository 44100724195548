import * as parts from '../comic-part-types';

export class NodeIds {
  private prefix: string;
  private counts: Map<parts.COMIC_NODE, number>;

  constructor(prefix = '') {
    this.prefix = prefix;
    this.counts = new Map<parts.COMIC_NODE, number>();
  }

  next(type: parts.COMIC_NODE): string {
    if (!this.counts.has(type)) {
      this.counts.set(type, 0);
    }

    const count = this.counts.get(type)!;
    this.counts.set(type, count + 1);

    return `${this.prefix}${type}-${count}`;
  }
}
