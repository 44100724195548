import { SelectHTMLAttributes } from 'react';
import './Select.css';

export type SelectProps<ValueType extends string = string> = {
  /**
   * Element ID. Must be unique on the page.
   */
  id: string;
  /**
   * User-facing label.
   */
  label: string;
  /**
   * Select's name within its form.
   */
  name: string;
  options: Array<SelectOption<ValueType>>;
  initialValue?: ValueType;
  /**
   * Text for an unselectable placeholder option.
   */
  placeholder?: string;
  /**
   * Invoked with the new value when this select's value changes.
   */
  onChange: (value: ValueType) => void;
  /**
   * ID to element that describes this select.
   */
  describedById?: string;
};

export type SelectOption<ValueType extends string> = {
  /**
   * User-facing label.
   */
  label: string;
  /**
   * Value emitted on change.
   */
  value: ValueType;
};

type OnChangeHandler = SelectHTMLAttributes<HTMLSelectElement>['onChange'];

/**
 * An uncontrolled select field.
 */
export function Select<ValueType extends string>(props: SelectProps<ValueType>) {
  const BLANK_VALUE = '';

  const handleChange: OnChangeHandler = (event) => {
    const selected = props.options.find(
      (opt) => opt.value === event.target.value
    );
    selected && props.onChange(selected.value);
  };

  return (
    <div className="c-select">
      <label htmlFor={props.id} className="c-select__label">
        {props.label}
      </label>

      <select
        id={props.id}
        name={props.name}
        defaultValue={props.initialValue || BLANK_VALUE}
        onChange={handleChange}
        aria-describedby={props.describedById || undefined}
      >
        {props.placeholder && (
          <option disabled hidden value={BLANK_VALUE} key="_placeholder_">
            {props.placeholder}
          </option>
        )}
        {props.options.map((option) => (
          <option key={option.label} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}
