import {
  observable,
  action,
  computed,
  flow,
  makeObservable,
} from 'mobx';
import debounce from 'lodash/debounce';

import { NO_TARGET_LINE } from './constants';
import {
  ScrollPosition,
  SpreadOutlineItem,
  OutlineItem,
  EditorCommandName,
  EditorCommand,
  ScriptReplacement,
} from './types';
import {
  Spread,
  Panel,
} from '../parser/nodes';
import { ScriptStore } from '../script';
import { ModalState } from '../modals/modal-state';
import * as parts from '../comic-part-types';
import {
  saveScript,
  SaveScriptOutcome,
  openScript,
  OpenScriptOutcome,
  savePdf,
  saveDocx,
  SaveDocxOutcome,
  ScriptFile,
  FileSystemHandle,
  isLegacyFileSystem,
  SavePdfOutcome,
} from '../file-system';
import { onError } from '../error-handling';
import { notifyCreatePdf, notifyCreateDocx, notifySuccess } from '../notifications';
import { SettingsStore } from '../settings';
import * as cache from '../script/script-cache';
import { outlineItems } from '../util/deep-equals';
import { InsightsStore } from '../insights';
import { ExportSettings } from '../export/settings';
import { KnownMetadata } from '../script/known-metadata';

type DocxModule = typeof import('../export/docx');
type PdfModule = typeof import('../export/pdf');

type UnsavedChangesOutcome = {
  /**
   * User canceled the replace operation.
   */
  type: 'cancel-replace'
} | {
  /**
   * No save needed. Either because script wasn't dirty or user is discarding
   * the changes.
   */
  type: 'no-save'
} | {
  /**
     * Script was dirty and user opened the save dialog. This is the outcome of
     * the save dialog.
     */
  type: 'save-dialog',
  saveOutcome: SaveScriptOutcome
};

export interface EditorStatus {
  /**
   * Location of the script as of the last successful save, if any. Otherwise
   * null if the script hasn't been saved yet.
   */
  handle: FileSystemHandle | null;
  /**
   * Content of the script.
   */
  source: string;
  /**
   * Has the script been changed since the last save?
   */
  dirty: boolean;
}

/**
 *
 * Important: Anything in this class that should be enhanced by mobx must also
 * be part of the decorate block below the class definition.
 */
export class EditorStore {
  /** Zero-based line number that the editor is on. */
  currentLine: number;
  /** Zero-based line number that the editor needs to scroll to. */
  targetLine: number;
  /**
   * Command for editor to run. When this changes to a non-null value the editor
   * runs the command once.
   */
  command: EditorCommand | null;

  /** Source of truth for script content. */
  scriptStore: ScriptStore;
  modals: ModalState;
  settingsStore: SettingsStore;
  insightsStore: InsightsStore;

  /** Handle to file where the script was last saved, if at all. */
  handle: FileSystemHandle | null;

  /** Has script content changed since the last save? */
  dirty: boolean;

  /**
   * The script content source of truth is managed by the editor component but
   * this will be the first value.
   *
   * `key` should be changed at the same time this is changed.
   */
  initialValue: string;

  /**
   * Key for react rendering purposes. This is changed when a whole new instance
   * of the editor is needed.
   */
  key: number;

  constructor(
    scriptStore: ScriptStore,
    modalState: ModalState,
    settingsStore: SettingsStore,
    insightsStore: InsightsStore,
  ) {
    makeObservable(this, {
      // observables
      currentLine: observable,
      targetLine: observable,
      command: observable,
      dirty: observable,
      initialValue: observable.ref,
      key: observable,
      handle: observable.ref,

      // computeds
      outlineItems: computed({
        equals: outlineItems
      }),
      currentSpread: computed,
      currentItemId: computed,
      currentPanelId: computed,
      currentSpreadId: computed,
      status: computed,
      scriptFile: computed,
      suggestedRootFilename: computed,

      // actions
      updateScroll: action,
      goToLine: action,
      runCommand: action,
      saveSucceeded: action,
      replaceScriptWithNew: action,
      replaceScriptWithOpened: action,
      replaceScriptWithInitial: action,
    });

    this.key = 0;
    this.currentLine = 0;
    this.targetLine = NO_TARGET_LINE;
    this.scriptStore = scriptStore;
    this.command = null;

    this.handle = null;
    this.dirty = false;
    this.initialValue = '';

    this.modals = modalState;
    this.settingsStore = settingsStore;
    this.insightsStore = insightsStore;
  }

  // computed values

  get outlineItems(): Array<OutlineItem> {
    const items: Array<OutlineItem> = [];

    for (const spread of this.scriptStore.locatedSpreads) {
      items.push({
        id: spread.id,
        type: 'spread',
        label: spread.label,
        lineNumber: spread.lineNumber,
        current: spread.id === this.currentItemId
      });

      const panels = spread.children
        .filter((child): child is Panel => child.type === parts.PANEL);

      for (const panel of panels) {
        items.push({
          id: panel.id,
          type: 'panel',
          panelNumber: panel.number,
          lineNumber: panel.lineNumber,
          current: panel.id === this.currentItemId,
          description: panel.description
        });
      }
    }

    return items;
  }

  get topOutlineItem(): SpreadOutlineItem {
    return {
      id: 'top',
      type: 'spread',
      label: 'Top',
      lineNumber: 0,
      current: this.currentItemId == null
    };
  }

  get currentSpread(): Spread | null {
    let currentSpread: Spread | null = null;

    for (const spread of this.scriptStore.locatedSpreads) {
      if (spread.lineNumber <= this.currentLine) {
        currentSpread = spread;
      } else {
        break;
      }
    }

    return currentSpread;
  }

  get currentItemId(): string | null {
    return this.currentPanelId || this.currentSpreadId || null;
  }

  get currentSpreadId(): string | null {
    return this.currentSpread ? this.currentSpread.id : null;
  }

  get currentPanelId(): string | null {
    let currentPanel: Panel | null = null;

    if (this.currentSpread) {
      for (const child of this.currentSpread.children) {
        if (child.lineNumber <= this.currentLine) {
          if (child.type === parts.PANEL) {
            currentPanel = child;
          }
        } else {
          break;
        }
      }
    }

    return currentPanel ? currentPanel.id : null;
  }

  get status(): EditorStatus {
    return {
      handle: this.handle,
      source: this.scriptStore.source,
      dirty: this.dirty
    };
  }

  get scriptFile(): ScriptFile {
    const scriptFile = new ScriptFile();
    scriptFile.source = this.scriptStore.source;

    if (this.handle) {
      scriptFile.handle = this.handle;
    }

    return scriptFile;
  }

  get suggestedRootFilename(): string {
    const fullScript = this.scriptStore.fullScript;
    return KnownMetadata.create(fullScript.preSpread).suggestedRootFilename;
  }

  // flows - A flow is like an action that does async stuff

  /**
   * Runs logic for "About".
   */
  executeAbout = flow(function* executeAbout(this: EditorStore)
    : Generator<Promise<void>, any, void> {

    yield this.modals.showAbout();
  });

  /**
   * Runs logic for "Settings".
   */
  executeSettings = flow(function* executeSettings(this: EditorStore)
    : Generator<Promise<void>, any, void>  {

    yield this.modals.showSettings();
  });

  /**
   * Runs logic for "New" menu item
   */
  executeNew = flow(function* executeNew(this: EditorStore)
    : Generator<Promise<UnsavedChangesOutcome>, any, UnsavedChangesOutcome> {

    // Try to save any unsaved changes
    const outcome = yield this.unsavedChanges();

    if (outcome.type === 'no-save' || (outcome.type === 'save-dialog' && outcome.saveOutcome.type === 'success')) {
      this.replaceScriptWithNew();
    }
  });

  executeOpen = flow(function* executeOpen(this: EditorStore)
    : Generator<Promise<UnsavedChangesOutcome> | Promise<OpenScriptOutcome>, any, UnsavedChangesOutcome | OpenScriptOutcome> {

    try {
      // Try to save any unsaved changes
      const outcome = (yield this.unsavedChanges()) as UnsavedChangesOutcome;

      if (outcome.type === 'no-save' || (outcome.type === 'save-dialog' && outcome.saveOutcome.type === 'success')) {
        // show open dialog
        const openOutcome = (yield openScript()) as OpenScriptOutcome;

        if (openOutcome.type === 'success') {
          // replace source and handle with the opened script
          this.replaceScriptWithOpened(openOutcome.result.source, openOutcome.result.handle);
        }
      }
    } catch (error) {
      onError(`Unable to open script`, error);
    }
  });

  private unsavedChanges = flow(function* unsavedChanges(this: EditorStore)
    : Generator<Promise<number> | Promise<SaveScriptOutcome>, UnsavedChangesOutcome, number | SaveScriptOutcome> {

    if (this.status.dirty) {
      const buttonIndex = (yield this.modals.showMessageBox({
        title: 'Save changes?',
        message: 'Your changes will be lost if you don\'t save them.',
        unsafeButtons: ["Don't Save"],
        buttons: ['Cancel', 'Save'],
        defaultIndex: 2,
        cancelIndex: 1,
      })) as number;

      if (buttonIndex === 0) {
        return { type: 'no-save' };
      } else if (buttonIndex === 1) {
        return { type: 'cancel-replace' };
      } else {
        const saveOutcome = (yield this.performSave(this.scriptFile, this.suggestedRootFilename)) as SaveScriptOutcome;

        /*
        Issue #44: The legacy file system api's save dialog cannot be awaited so
        this 2nd modal acts as the gate to know when it's okay to continue the
        parent operation.
        */
        if (isLegacyFileSystem) {
          const index = (yield this.modals.showMessageBox({
            title: 'Save changes?',
            message: 'Your changes will be lost if you don\'t save them.',
            buttons: ['Cancel', "I've saved my changes"],
            defaultIndex: 1,
            cancelIndex: 0,
          })) as number;

          if (index === 0) {
            return { type: 'cancel-replace' };
          }
        }

        return {
          type: 'save-dialog',
          saveOutcome
        };
      }
    } else {
      return { type: 'no-save' };
    }
  });

  executeSave = flow(function* executeSave(this: EditorStore)
    : Generator<Promise<SaveScriptOutcome>, any, SaveScriptOutcome> {

    try {
      yield this.performSave(this.scriptFile, this.suggestedRootFilename);
    } catch (error) {
      onError(`Unable to save script`, error);
    }
  });

  executeSaveAs = flow(function* executeSaveAs(this: EditorStore)
    : Generator<Promise<SaveScriptOutcome>, any, SaveScriptOutcome> {

    try {
      yield this.performSave(this.scriptFile.copy(), this.suggestedRootFilename);
    } catch (error) {
      onError(`Unable to save script`, error);
    }
  });

  private performSave = flow(function* performSave(this: EditorStore, scriptFile: ScriptFile, suggestedRootFilename: string) {
    const outcome = (
      yield saveScript(scriptFile, suggestedRootFilename)
    ) as SaveScriptOutcome;

    if (outcome.type === 'success') {
      notifySuccess('Saved');

      this.dirty = false;

      if (outcome.result) {
        this.handle = outcome.result;
      }
    }

    return outcome;
  });

  executeExportDocx = flow(function* executeExportDocx(this: EditorStore) {
    yield notifyCreateDocx(() => this.exportDocx());
  });

  private exportDocx = flow(function* exportDocx(this: EditorStore)
    : Generator<Promise<DocxModule> | Promise<Blob> | Promise<SaveDocxOutcome>, any, DocxModule | Blob | SaveDocxOutcome> {

    try {
      const docxModule = (yield import('../export/docx')) as DocxModule;

      const fullScript = this.scriptStore.fullScript;
      const settings = this.settingsStore.settings;
      const blob = (yield docxModule.generate(fullScript, new ExportSettings(settings))) as Blob;

      yield saveDocx(blob, this.suggestedRootFilename);
    } catch (error) {
      onError(`Unable to create docx`, error);
    }
  });

  executeExportPdf = flow(function* executeExportPdf(this: EditorStore) {
    yield notifyCreatePdf(() => this.exportPdf());
  });

  private exportPdf = flow(function* exportPdf(this: EditorStore)
    : Generator<Promise<PdfModule> | Promise<Blob> | Promise<SavePdfOutcome>, any, PdfModule | Blob | SavePdfOutcome> {

    try {
      const pdfModule = (yield import('../export/pdf')) as PdfModule;

      const fullScript = this.scriptStore.fullScript;
      const settings = this.settingsStore.settings;
      const blob = (yield pdfModule.generatePdf(fullScript, new ExportSettings(settings))) as Blob;

      yield savePdf(blob, this.suggestedRootFilename);
    } catch (error) {
      onError(`Unable to create PDF`, error);
    }
  });

  // actions

  updateScroll(scrollWindow: ScrollPosition): void {
    this.currentLine = scrollWindow.currentLine;
    this.targetLine = NO_TARGET_LINE;
  }

  goToLine(lineNumber: number): void {
    this.targetLine = lineNumber;
  }

  runCommand(name: EditorCommandName): void {
    this.command = {
      name
    };
  }

  /**
   * Notify the store that a script was just saved successfully.
   *
   * @param handle
   */
  saveSucceeded(handle: FileSystemHandle): void {
    this.handle = handle;
    this.dirty = false;
  }

  /**
   * Replace script with a new, blank script.
   */
  replaceScriptWithNew(): void {
    this.replaceScript({
      handle: null,
      source: ''
    });
  }

  /**
   * Replace script with a script that was just opened.
   *
   * @param handle
   * @param source
   */
  replaceScriptWithOpened(source: string, handle?: FileSystemHandle): void {
    this.replaceScript({
      handle: handle || null,
      source
    });
  }

  replaceScriptWithInitial(source: string): void {
    this.replaceScript({
      handle: null,
      source
    })
  }

  /**
   * Update the script due to user input.
   *
   * @param source
   */
  updateScript(source: Array<string>): void {
    this.scriptStore.updateScript(source);
    this.debouncedCacheScript(source);

    this.dirty = true;
  }

  executeToggleInsights(): void {
    this.insightsStore.toggle();
  }

  // helpers

  private debouncedCacheScript = debounce((lines: Array<string>) => {
    cache.set(lines.join('\n'));
  }, 1000);

  private replaceScript(script: ScriptReplacement): void {
    this.initialValue = script.source;
    this.key += 1;
    this.dirty = false;

    this.scriptStore.replaceScript(script.source);
    this.handle = script.handle;

    cache.set(script.source);
  }
}
