import Honeybadger from '@honeybadger-io/js';
import { notifyError } from '../notifications';

export { allowErrorReporting } from './honeybadger';
export { default as ErrorBoundary } from './components/ErrorBoundary';

/**
 * Report an error.
 *
 * @param humanMessage Error message to show to user
 * @param error
 */
export function onError(humanMessage: string, error: Error) {
  // Notify user that something failed
  notifyError(humanMessage);

  /*
   * Notify devs that something failed.
   *
   * This doesn't necessarily send to honeybadger. It depends on the environment
   * and if user has opted in. See honeybadger file for more details.
   */
  Honeybadger.notify(error, {
    context: {
      humanMessage
    }
  });

  console.error(humanMessage, error);
}