import {
  observable,
  makeObservable,
} from 'mobx';
import { EditorStore } from '../editor';
import { TopLevelMenu } from '../components/menu/Menu';
import { guideUrl } from '../util/static-site-urls';
import { twitter } from '../util/social-urls';

/**
 * State and logic behind the app menu.
 *
 * Important: Anything in this class that should be enhanced by mobx must also
 * be part of the decorate block below the class definition.
 */
export class MenuStore {
  /**
   * Top level menu items.
   */
  data: Array<TopLevelMenu>;

  constructor(editor: EditorStore) {
    makeObservable(this, {
      data: observable.ref
    });

    this.data = [
      {
        menuName: 'ComicWriter',
        menuChildren: [
          {
            menuName: 'About',
            type: 'item',
            onClick: () => editor.executeAbout(),
          },
          {
            menuName: `Follow us on Twitter`,
            type: 'item',
            onClick: () => {
              window.open(twitter);
            }
          },
          { type: 'divider' },
          {
            menuName: 'Settings',
            type: 'item',
            onClick: () => editor.executeSettings(),
            hotkey: ['Shift', 'Meta', ',']
          }
        ]
      },
      {
        menuName: 'File',
        menuChildren: [
          {
            menuName: 'New',
            type: 'item',
            onClick: () => editor.executeNew()
          },
          { type: 'divider' },
          {
            menuName: 'Open...',
            type: 'item',
            onClick: () => editor.executeOpen(),
            hotkey: ['Meta', 'O']
          },
          { type: 'divider' },
          {
            menuName: 'Save',
            type: 'item',
            onClick: () => editor.executeSave(),
            hotkey: ['Meta', 'S']
          },
          {
            menuName: 'Save As...',
            type: 'item',
            onClick: () => editor.executeSaveAs(),
            hotkey: ['Shift', 'Meta', 'S']
          },
          { type: 'divider' },
          {
            menuName: 'Export As Word Doc...',
            type: 'item',
            onClick: () => editor.executeExportDocx(),
          },
          {
            menuName: 'Export As PDF...',
            type: 'item',
            onClick: () => editor.executeExportPdf(),
          },
        ]
      },
      {
        menuName: 'View',
        menuChildren: [
          {
            menuName: 'Insights',
            type: 'item',
            onClick: () => editor.executeToggleInsights(),
            hotkey: ['Shift', 'Meta', 'I'],
          }
        ]
      },
      {
        menuName: 'Help',
        menuChildren: [
          {
            menuName: `Writer's Guide`,
            type: 'item',
            onClick: () => {
              window.open(guideUrl);
            },
            hotkey: ['Meta', '/']
          }
        ]
      }
    ];
  }
}
