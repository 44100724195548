class LetteringFlags {
  subjectDone = false;

  inModifier = false;
  modifierDone = false;

  inContent = false;
  contentDone = false;

  copy() {
    const result = new LetteringFlags();

    result.subjectDone = this.subjectDone;

    result.inModifier = this.inModifier;
    result.modifierDone = this.modifierDone;

    result.inContent = this.inContent;
    result.contentDone = this.contentDone;

    return result;
  }
}

export class State {
  /** How many pages seen so far */
  pageCount: number;
  /** Non-null when currently tokenizing a lettering line */
  lettering: LetteringFlags | null;
  inUnfinishedParagraphBold: boolean;

  constructor() {
    this.pageCount = 0;
    this.lettering = null;
    this.inUnfinishedParagraphBold = false;
  }

  enterLettering() {
    this.lettering = new LetteringFlags();
  }

  exitLettering() {
    this.lettering = null;
  }

  copy() {
    const result = new State();
    result.pageCount = this.pageCount;
    result.lettering = this.lettering ? this.lettering.copy() : this.lettering;
    result.inUnfinishedParagraphBold = this.inUnfinishedParagraphBold;
    return result;
  }

  resetLineState() {
    this.lettering = null;
    this.inUnfinishedParagraphBold = false;
  }
}
