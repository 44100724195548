import React, { useCallback } from 'react';
import { Hotkey } from '../../../components/hotkey/Hotkey';
import ToolBar from '../../../components/tool-bar/ToolBar';
import Button from '../../../components/button/Button'
import ToolTip from '../../../components/tool-tip/ToolTip'
import { Hotkeys } from '../../../hotkeys'
import { useStore } from '../../../store/use-store';
import { EditorCommandName } from '../../types';

import './EditorToolBar.css';

/**
 * ToolBar that sits above the editor.
 */
export const EditorToolBar = () => {
  const { editor: editorStore } = useStore();

  const onClick = useCallback((commandName: EditorCommandName) => {
    editorStore.runCommand(commandName);
  }, [editorStore]);

  return (
    <div className="c-editor-tool-bar">
      <ToolBar>
        {Hotkeys
          .filter(hotkey => hotkey.inToolbar)
          .map((hotkey, i) =>
            <ToolTip key={i}>
              <Hotkey animated keyCombo={hotkey.hotkey} />
              <Button onClick={() => onClick(hotkey.command)}>
                {hotkey.name}
              </Button>
            </ToolTip>)}
        <React.Fragment />
      </ToolBar>
    </div>
  );
};
