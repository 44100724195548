import { ComicWriterSettings } from '../settings/types';

export class ExportSettings {
  private appSettings: ComicWriterSettings;

  constructor(appSettings: ComicWriterSettings) {
    this.appSettings = {...appSettings};
  }

  get boldLetteringIsBold() {
    return this.appSettings.boldLetteringExportStyle === 'bold'
      || this.appSettings.boldLetteringExportStyle === 'bold-and-underline';
  }

  get boldLetteringIsUnderlined() {
    return this.appSettings.boldLetteringExportStyle === 'underline'
      || this.appSettings.boldLetteringExportStyle === 'bold-and-underline';
  }
}
