import { useEffect, useMemo } from 'react';
import { Menu } from '../../components/menu/Menu';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../store/use-store';

import { TopLevelMenu, ChildItem } from '../../components/menu/Menu';
import {
  isKeyCombo,
  hasModifier,
} from '../../util/keyboard';


export const AppMenu = observer(() => {
  const { menu } = useStore();

  useHotkeyBindings(menu.data);

  return (
    <Menu menuData={menu.data} />
  );
});


function useHotkeyBindings(menuData: Array<TopLevelMenu>) {
  // build up list of hotkeys and their handlers
  const hotkeyHandlers = useMemo(() => {
    return menuData
      .flatMap(topLevelMenu => {
        return topLevelMenu.menuChildren
          .filter((child): child is ChildItem => child.type === 'item')
          .filter(item => item.hotkey)
          // sort by key count, most keys first
          .sort((a, b) => b.hotkey!.length - a.hotkey!.length)
          .map(item => {
            return {
              check: isKeyCombo(item.hotkey!),
              handler: item.onClick
            };
          });
      });
  }, [menuData]);

  // wire up a listener for all hotkeys
  useEffect(() => {
    if (hotkeyHandlers.length > 0) {
      const listener = (event: KeyboardEvent) => {
        // Bailout asap because this fires as user types
        if (!hasModifier(event)) {
          return;
        }

        for (const pair of hotkeyHandlers) {
          if (pair.check(event)) {
            event.preventDefault();
            pair.handler();
            break;
          }
        }
      };

      document.addEventListener('keydown', listener);
      return () => document.removeEventListener('keydown', listener);
    } else {
      return;
    }
  }, [hotkeyHandlers]);
}
