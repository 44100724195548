import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { BarGraph } from '../../components/bar-graph/BarGraph';
import { useStore } from '../../store';

import './Insights.css';

export const Insights = observer(() => {
  const { insights, editor } = useStore();
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);

  return (
    <div className="c-insights" onMouseLeave={() => setHighlightedIndex(null)}>
      <section>
        <h2 className="u-font-size--saya">Panels per page</h2>
        <BarGraph
          stats={insights.panelCountsBySpread}
          height="110px"
          highlightedIndex={highlightedIndex}
          onBarHover={setHighlightedIndex}
          onBarSelection={lineNumber => editor.goToLine(lineNumber)}
          xAxisLabel="Page"
          caption="Panels per page"
        />
      </section>
      <section>
        <h2 className="u-font-size--saya">Spoken words per page</h2>
        <BarGraph
          stats={insights.spokenWordCountsBySpread}
          height="110px"
          highlightedIndex={highlightedIndex}
          onBarHover={setHighlightedIndex}
          onBarSelection={lineNumber => editor.goToLine(lineNumber)}
          xAxisLabel="Page"
          caption="Spoken words per page"
        />
      </section>
    </div>
  );
});
