import { Router } from '@reach/router';
import { Writer } from './containers/Writer';

/**
 * Top-level routes.
 *
 * There are other areas where the content to render depends on the url so this
 * isn't the only place where a `<Router>` is in the app.
 */
export default function Routes() {
  return (
    <Router>
      <Writer path="/" />
    </Router>
  );
}
