import { RootStore } from './store';
import { RootStoreContext } from './store/root-store-context';
import Root from './containers/Root';
import { ErrorBoundary } from './error-handling';

type Props = {
  store: RootStore;
};

const App = ({ store }: Props) => (
  <ErrorBoundary>
    <RootStoreContext.Provider value={store}>
      <Root />
    </RootStoreContext.Provider>
  </ErrorBoundary>
);

export default App;
