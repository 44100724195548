import { Editor } from 'codemirror';

/**
 * If the cursor isn't already at the start of a line, insert a newline at the
 * cursor.
 *
 * @param cm editor
 * @param origin Used for CodeMirror's undo/redo tracking purposes
 */
export function ensureCursorAtLineStart(cm: Editor, origin?: string) {
  const cursor = cm.getCursor();

  if (cursor.ch !== 0) {
    cm.replaceRange('\n', cursor, undefined, origin);
  }
}

export function ensureCursorOnBlankLine(cm: Editor, origin?: string) {
  ensureCursorAtLineStart(cm, origin);

  const cursor = cm.getCursor();
  const line = cm.getLine(cursor.line);

  if (line !== '') {
    // newline at front of line
    cm.replaceRange('\n', {
      line: cursor.line,
      ch: 0
    }, undefined, origin);

    // move cursor to newly created blank line
    cm.setCursor(cursor.line, 0, {
      origin
    });
  }
}

/**
 * Chain some functions together to form a CodeMirror command. Functions will be
 * run sequentially in a single CodeMirror operation.
 */
export function chain(fns: Array<(cm: Editor) => void>): (cm: Editor) => void {
  return function chainedCommand(cm) {
    cm.operation(() => fns.forEach(fn => fn(cm)));
  };
}

/**
 * Focus the editor.
 *
 * When commands are triggered by a toolbar button click, focus goes to the
 * button. User sees something in the editor change but they don't have a cursor
 * so they can't just start typing in the editor and it's weird.
 *
 * A lot of commands start with a focus call to ensure user will have a cursor
 * available after the button action is applied.
 */
export function focus(cm: Editor): void {
  cm.focus();
}
