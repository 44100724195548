import { mark, stop } from 'marky';

export function start(name: string) {
  if (process.env.NODE_ENV !== 'production') {
    mark(name);
  }
}

export function end(name: string) {
  if (process.env.NODE_ENV !== 'production') {
    stop(name);
  }
}
