import {
  observable,
  action,
  makeObservable,
} from 'mobx';

import { ComicWriterSettings } from './types';
import { upsert } from './settings-api';

/**
 * Important: Anything in this class that should be enhanced by mobx must also
 * be part of the decorate block below the class definition.
 */
export default class SettingsStore {
  settings: ComicWriterSettings;

  constructor(initialSettings: ComicWriterSettings) {
    makeObservable(this, {
      // observables
      settings: observable,

      // actions
      _sync: action
    });

    this.settings = {...initialSettings};
  }

  // Not marked as a mobx action because it doesn't update any observables.
  update(settings: Partial<ComicWriterSettings>) {
    upsert(settings);
  }

  /**
   * For internal use only. To update settings use `update()`.
   */
  _sync(settings: ComicWriterSettings) {
    Object.assign(this.settings, settings);
  }
}
