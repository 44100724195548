import { observer } from 'mobx-react-lite';
import { useStore } from '../../../store/use-store';
import Switch from '../../../components/switch/Switch';
import { Link } from '../../../components/link/Link';
import { privacyUrl, guideUrl } from '../../../util/static-site-urls';

export function About() {
  return (
    <>
      <h2 className="u-font-size--maria">What is this?</h2>
      <p>
        ComicWriter is a free web app for producing North American style comic scripts. ComicWriter focuses on efficiency, simplicity, and privacy.
      </p>
    </>
  );
}

export const ErrorReporting = observer(() => {
  const { settings } = useStore();

  const onChangeErrorReporting = (reportErrors: boolean) => {
    settings.update({
      reportErrors
    });
  };

  return (
    <>
      <h2 className="u-font-size--maria">Error Reporting</h2>
      <p>
        To improve ComicWriter, we use a 3rd party service to detect errors and crashes. See <Link url={privacyUrl} newTab>our privacy policy</Link>. This is optional and can be changed later.
      </p>
      <Switch
        on={settings.settings.reportErrors}
        id="error-reporting"
        block
        onString="Yes"
        offString="No"
        onChange={onChangeErrorReporting}
      >
        Allow error reporting?
      </Switch>
    </>
  );
});

export function Learn() {
  return (
    <>
      <h2 className="u-font-size--maria">Learn</h2>
      <p>
        To learn the basics of ComicWriter you can <Link url={guideUrl} newTab>browse the Writer's Guide</Link>.
      </p>
      <p>
        The guide will be accessible later through the in-app help menu.
      </p>
    </>
  );
}