import * as parts from '../comic-part-types';
import { NodeIds } from '../script/node-ids';
import { Sequence } from '../util/sequence';

/**
 * Nodes that can be in the upper script area before spreads.
 */
export type PreSpread = Metadata | Paragraph | BlankLine;

/**
 * Nodes that can be a child of a spread.
 */
export type SpreadChild = Panel | PanelChild;

/**
 * Nodes that can be a child of a panel.
 */
export type PanelChild = Lettering | Paragraph | BlankLine;

/** Any comic node that can appear anywhere in the script. */
export type ComicNode = Spread | Panel | Leaf;

/**
 * Nodes that don't contain other nodes.
 */
export type Leaf = Lettering | Paragraph | BlankLine | Metadata;

/** Lettering nodes */
export type Lettering = Dialogue | Caption | Sfx;

interface NodeIdentity {
  /**
   * Identifier for this node. After the script is numbered this will be unique
   * across all other nodes in the script.
   *
   * **Note:** This will have a nonsense value until script is numbered.
   */
  id: string;
  /**
   * Zero based line number.
   *
   * **Note:** This will have a nonsense value until script is numbered.
   */
  lineNumber: number;
}

export class Spread implements NodeIdentity {
  // core spread properties
  type: typeof parts.SPREAD;
  pageCount: number;
  children: Array<SpreadChild>;

  /**
   * One-based page number this spread starts on.
   *
   * **Note:** This will have a nonsense value until script is numbered.
   */
  startPage: number;

  // properties that are derived from children
  panelCount: number;
  speakers: Array<string>;
  dialogueCount: number;
  captionCount: number;
  sfxCount: number;
  dialogueWordCount: number;
  captionWordCount: number;

  id: string;
  lineNumber: number;

  constructor() {
    this.type = parts.SPREAD;
    this.pageCount = 0;
    this.children = [];
    this.startPage = 0;

    this.panelCount = 0;
    this.speakers = [];
    this.dialogueCount = 0;
    this.captionCount = 0;
    this.sfxCount = 0;
    this.dialogueWordCount = 0;
    this.captionWordCount = 0;

    this.id = '';
    this.lineNumber = 0;
  }

  /**
   * Label for this spread.
   *
   * Examples:
   *
   * - "Page 1"
   * - "Pages 1-2"
   *
   * **Note:** This will have a nonsense value until script is numbered.
   */
  get label(): string {
    return `Page${this.pageCount === 1 ? '' : 's'} ${this.pageRangeLabel}`;
  }

  /**
   * Label for page range covered by this spread.
   *
   * Examples:
   *
   * - "1"
   * - "1-2"
   *
   * **Note:** This will have a nonsense value until script is numbered.
   */
  get pageRangeLabel(): string {
    return this.pageCount === 1
      ? this.startPage.toString()
      : `${this.startPage}-${this.endPage}`;
  }

  /**
   * Page number of the last page that is part of this spread.
   *
   * **Note:** This will have a nonsense value until script is numbered.
   */
  get endPage(): number {
    return this.startPage + (this.pageCount - 1);
  }

  /**
   * Spoken word count: total count of dialogue words and caption words that are
   * anywhere in this spread.
   */
  get wordCount(): number {
    return this.dialogueWordCount + this.captionWordCount;
  }

  /**
   * The page numbers spanned by this spread.
   *
   * length will always be >= 1
   */
  get pagesSpanned(): Array<number> {
    return Array(this.pageCount)
      .fill(null)
      .map((_, index) => this.startPage + index);
  }

  addChild(child: SpreadChild): void {
    this.children.push(child);

    switch (child.type) {
      case parts.PANEL:
        this.panelCount        += 1;
        this.captionCount      += child.captionCount;
        this.captionWordCount  += child.captionWordCount;
        this.dialogueCount     += child.dialogueCount;
        this.dialogueWordCount += child.dialogueWordCount;
        this.sfxCount          += child.sfxCount;
        this.speakers.push(...child.speakers);
        break;
      case parts.DIALOGUE:
        this.dialogueCount     += 1;
        this.dialogueWordCount += child.wordCount;
        break;
      case parts.CAPTION:
        this.captionCount      += 1;
        this.captionWordCount  += child.wordCount;
        break;
    }
  }

  shallowCopy(): Spread {
    const copy = new Spread();

    copy.type = this.type;
    copy.pageCount = this.pageCount;
    copy.children = this.children;

    copy.startPage = this.startPage;

    copy.panelCount = this.panelCount;
    copy.speakers = this.speakers;
    copy.dialogueCount = this.dialogueCount;
    copy.captionCount = this.captionCount;
    copy.sfxCount = this.sfxCount;
    copy.dialogueWordCount = this.dialogueWordCount;
    copy.captionWordCount = this.captionWordCount;

    copy.id = this.id;
    copy.lineNumber = this.lineNumber;

    return copy;
  }

  /**
   * Convert this spread to a "located" spread which is a spread that has a spot
   * in a comic script.
   *
   * @param nodeIds
   * @param lineNumbers
   * @param startPage
   */
  toLocated(nodeIds: NodeIds, lineNumbers: Sequence, startPage: number): Spread {
    const copy = this.shallowCopy();

    copy.id = nodeIds.next(copy.type);
    copy.lineNumber = lineNumbers.next();
    copy.startPage = startPage;

    copy.children = this.children.map(child => child.toLocated(nodeIds, lineNumbers));

    return copy;
  }
}

export class Panel implements NodeIdentity {
  // core panel properties
  type: typeof parts.PANEL;
  number: number;
  children: Array<PanelChild>;

  // properties that are derived from children
  speakers: Array<string>;
  dialogueCount: number;
  captionCount: number;
  sfxCount: number;
  dialogueWordCount: number;
  captionWordCount: number;
  description: string;

  id: string;
  lineNumber: number;

  constructor() {
    this.type = parts.PANEL;
    this.number = 0;
    this.children = [];

    this.speakers = [];
    this.dialogueCount = 0;
    this.captionCount = 0;
    this.sfxCount = 0;

    this.dialogueWordCount = 0;
    this.captionWordCount = 0;
    this.description = '';

    this.id = '';
    this.lineNumber = 0;
  }

  /**
   * Human-readable label for this panel.
   *
   * **Note:** This will have a nonsense value until script is numbered.
   */
  get label(): string {
    return `Panel ${this.number}`;
  }

  get letteringCount(): number {
    return this.dialogueCount + this.captionCount + this.sfxCount;
  }

  /**
   * Total count of dialogue words and caption words in this panel.
   */
  get wordCount(): number {
    return this.dialogueWordCount + this.captionWordCount;
  }

  addChild(child: PanelChild): void {
    this.children.push(child);

    switch (child.type) {
      case parts.PARAGRAPH: {
        if (this.letteringCount === 0 && !this.description) {
          this.description = child.toPlainText();
        }
        break;
      }
      case parts.CAPTION: {
        this.captionCount += 1;
        this.captionWordCount += child.wordCount;
        break;
      }
      case parts.DIALOGUE: {
        this.dialogueCount += 1;
        this.dialogueWordCount += child.wordCount;
        this.speakers.push(child.speaker);
        break;
      }
      case parts.SFX: {
        this.sfxCount += 1;
        break;
      }
    }
  }

  shallowCopy(): Panel {
    const copy = new Panel();

    copy.type = this.type;
    copy.number = this.number;
    copy.children = this.children;

    copy.speakers = this.speakers;
    copy.dialogueCount = this.dialogueCount;
    copy.captionCount = this.captionCount;
    copy.sfxCount = this.sfxCount;

    copy.dialogueWordCount = this.dialogueWordCount;
    copy.captionWordCount = this.captionWordCount;
    copy.description = this.description;

    copy.id = this.id;
    copy.lineNumber = this.lineNumber;

    return copy;
  }

  /**
   * Convert this panel to a "located" panel which is a panel that has a spot in
   * a comic script.
   *
   * @param nodeIds
   * @param lineNumbers
   */
  toLocated(nodeIds: NodeIds, lineNumbers: Sequence): Panel {
    const copy = this.shallowCopy();

    copy.id = nodeIds.next(copy.type);
    copy.lineNumber = lineNumbers.next();

    copy.children = this.children.map(child => child.toLocated(nodeIds, lineNumbers));

    return copy;
  }
}

export class Paragraph implements NodeIdentity {
  type: typeof parts.PARAGRAPH;
  content: Array<TextChunk>;

  id: string;
  lineNumber: number;

  constructor() {
    this.type = parts.PARAGRAPH;
    this.content = [];

    this.id = '';
    this.lineNumber = 0;
  }

  toPlainText(): string {
    return this.content
      .map(chunk => chunk.content)
      .join('');
  }

  shallowCopy(): Paragraph {
    const copy = new Paragraph();

    copy.type = this.type;
    copy.content = this.content;

    copy.id = this.id;
    copy.lineNumber = this.lineNumber;

    return copy;
  }

  /**
   * Convert this to a "located" paragraph which is a paragraph that has a spot
   * in a comic script.
   *
   * @param nodeIds
   * @param lineNumbers
   */
  toLocated(nodeIds: NodeIds, lineNumbers: Sequence): Paragraph {
    const copy = this.shallowCopy();

    copy.id = nodeIds.next(copy.type);
    copy.lineNumber = lineNumbers.next();

    return copy;
  }
}

export class Metadata implements NodeIdentity {
  type: typeof parts.METADATA;
  name: string;
  value: string;

  id: string;
  lineNumber: number;

  constructor(name: Metadata['name'], value: Metadata['value']) {
    this.type = parts.METADATA;
    this.name = name;
    this.value = value;

    this.id = '';
    this.lineNumber = 0;
  }

  shallowCopy(): Metadata {
    const copy = new Metadata(this.name, this.value);

    copy.id = this.id;
    copy.lineNumber = this.lineNumber;

    return copy;
  }

  /**
   * Convert this to a "located" metadata which is a metadata that has a spot
   * in a comic script.
   *
   * @param nodeIds
   * @param lineNumbers
   */
  toLocated(nodeIds: NodeIds, lineNumbers: Sequence): Metadata {
    const copy = this.shallowCopy();

    copy.id = nodeIds.next(copy.type);
    copy.lineNumber = lineNumbers.next();

    return copy;
  }
}

export class Dialogue implements NodeIdentity {
  type: typeof parts.DIALOGUE;
  number: number;
  speaker: string;
  modifier: string;
  content: Array<TextChunk>;
  wordCount: number;

  id: string;
  lineNumber: number;

  constructor() {
    this.type = parts.DIALOGUE;
    this.number = 0;
    this.speaker = '';
    this.modifier = '';
    this.content = [];
    this.wordCount = 0;

    this.id = '';
    this.lineNumber = 0;
  }

  shallowCopy() {
    const copy = new Dialogue();

    copy.type = this.type;
    copy.number = this.number;
    copy.speaker = this.speaker;
    copy.modifier = this.modifier;
    copy.content = this.content;
    copy.wordCount = this.wordCount;

    copy.id = this.id;
    copy.lineNumber = this.lineNumber;

    return copy;
  }

  /**
   * Convert this to a "located" dialogue which is a dialogue that has a spot
   * in a comic script.
   *
   * @param nodeIds
   * @param lineNumbers
   */
  toLocated(nodeIds: NodeIds, lineNumbers: Sequence): Dialogue {
    const copy = this.shallowCopy();

    copy.id = nodeIds.next(copy.type);
    copy.lineNumber = lineNumbers.next();

    return copy;
  }
}

export class Caption implements NodeIdentity {
  type: typeof parts.CAPTION;
  number: number;
  modifier: string;
  content: Array<TextChunk>;
  wordCount: number;

  id: string;
  lineNumber: number;

  constructor() {
    this.type = parts.CAPTION;
    this.number = 0;
    this.modifier = '';
    this.content = [];
    this.wordCount = 0;

    this.id = '';
    this.lineNumber = 0;
  }

  shallowCopy(): Caption {
    const copy = new Caption();

    copy.type = this.type;
    copy.number = this.number;
    copy.modifier = this.modifier;
    copy.content = this.content;
    copy.wordCount = this.wordCount;

    copy.id = this.id;
    copy.lineNumber = this.lineNumber;

    return copy;
  }

  /**
   * Convert this to a "located" caption which is a caption that has a spot
   * in a comic script.
   *
   * @param nodeIds
   * @param lineNumbers
   */
  toLocated(nodeIds: NodeIds, lineNumbers: Sequence): Caption {
    const copy = this.shallowCopy();

    copy.id = nodeIds.next(copy.type);
    copy.lineNumber = lineNumbers.next();

    return copy;
  }
}

export class Sfx implements NodeIdentity {
  type: typeof parts.SFX;
  number: number;
  modifier: string;
  content: Array<TextChunk>;

  id: string;
  lineNumber: number;

  constructor() {
    this.type = parts.SFX;
    this.number = 0;
    this.modifier = '';
    this.content = [];

    this.id = '';
    this.lineNumber = 0;
  }

  shallowCopy(): Sfx {
    const copy = new Sfx();

    copy.type = this.type;
    copy.number = this.number;
    copy.modifier = this.modifier;
    copy.content = this.content;

    copy.id = this.id;
    copy.lineNumber = this.lineNumber;

    return copy;
  }

  /**
   * Convert this to a "located" sfx which is a sfx that has a spot in a comic
   * script.
   *
   * @param nodeIds
   * @param lineNumbers
   */
  toLocated(nodeIds: NodeIds, lineNumbers: Sequence): Sfx {
    const copy = this.shallowCopy();

    copy.id = nodeIds.next(copy.type);
    copy.lineNumber = lineNumbers.next();

    return copy;
  }
}

export class TextChunk {
  type: typeof parts.TEXT | typeof parts.BOLD_TEXT | typeof parts.URL_TEXT;
  content: string;

  static bold(content: string): TextChunk {
    return new TextChunk(parts.BOLD_TEXT, content);
  }

  static plain(content: string): TextChunk {
    return new TextChunk(parts.TEXT, content);
  }

  constructor(type: TextChunk['type'], content: string) {
    this.type = type;
    this.content = content;
  }

  get isPlain(): boolean {
    return this.type === parts.TEXT;
  }

  get isBold(): boolean {
    return this.type === parts.BOLD_TEXT;
  }

  get isUrl(): boolean {
    return this.type === parts.URL_TEXT;
  }
}

export class BlankLine implements NodeIdentity {
  type: typeof parts.BLANK;
  id: string;
  lineNumber: number;

  constructor() {
    this.type = parts.BLANK;
    this.id = '';
    this.lineNumber = 0;
  }

  shallowCopy(): BlankLine {
    const copy = new BlankLine();

    copy.type = this.type;
    copy.id = this.id;
    copy.lineNumber = this.lineNumber;

    return copy;
  }

  /**
   * Convert this to a "located" blank which is a blank that has a spot in a
   * comic script.
   *
   * @param nodeIds
   * @param lineNumbers
   */
  toLocated(nodeIds: NodeIds, lineNumbers: Sequence): BlankLine {
    const copy = this.shallowCopy();

    copy.id = nodeIds.next(copy.type);
    copy.lineNumber = lineNumbers.next();

    return copy;
  }
}

/**
 * The lines from a script that make up a spread and everything inside it.
 */
export interface SpreadLines {
  /**
   * The spread line.
   */
  spread: string;
  /**
   * The child lines of the spread.
   */
  children: Array<string>;
}
