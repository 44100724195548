import { StringStream } from 'codemirror';
import { State } from './state';

/*
 * These values become css classes so keep them synced with theme.css file.
 * For more info, see https://codemirror.net/doc/manual.html#modeapi
 *
 * These also show up in the `type` property of CodeMirror tokens.
 * See cm.getTokenAt(), cm.getLineTokens() and cm.getTokenTypeAt() in
 * https://codemirror.net/doc/manual.html
 */

// Token classes. These are applied to individual tokens of a line.
// CodeMirror adds a 'cm-' prefix to these before putting them in the dom.
const PAGE = 'page';
const PANEL = 'panel';
const METADATA = 'metadata';
export const PARAGRAPH = 'paragraph';
export const PARAGRAPH_BOLD = 'paragraph-bold';
const PARAGRAPH_URL = 'paragraph-url';

export const LETTERING_SUBJECT = 'lettering-subject';
export const LETTERING_MODIFIER = 'lettering-modifier';
export const LETTERING_CONTENT = 'lettering-content';
export const LETTERING_BOLD = 'lettering-bold';

// Line classes. These are applied to entire lines.
// CM removes the 'line-' prefix before putting them in the dom.
export const LETTERING_LINE = 'line-cm-lettering';
const SFX_LINE = 'line-cm-sfx';
const CAPTION_LINE = 'line-cm-caption';
const DIALOGUE_LINE = 'line-cm-dialogue';
/*
 * End of values that become css classes
 */

// common patterns and stuff to identifying bold and urls
const AT_BOLD_STARS = /^\*\*/;
const AT_URL = /^https?:\/\/\S+[\w=?#/]/;
const UP_TO_BOLD_STARS = /^(.*?)\*\*/;
const UP_TO_URL = /^(.*?)https?:\/\/\S+[\w=?#/]/;
const FIRST_CHAR_BOLD_STARS = '*';
const FIRST_CHAR_URL = 'h';

export function token(stream: StringStream, state: State): string | null {
  if (stream.sol()) {
    state.resetLineState();

    if (stream.peek() === '\t') {
      stream.next();
      state.enterLettering();
      return null;
    }

    if (stream.match(/^pages? \d+(-(\d+)?)?$/i)) {
      state.pageCount += 1;
      return PAGE;
    }

    if (stream.match(/^panel \d+$/i)) {
      return PANEL;
    }

    if (
      state.pageCount === 0
      // not metadata if first colon is the colon in https://
      && !stream.match(/^[^:]*(https?:\/\/)/, false)
      && stream.match(/^(.+): ?(.+)/)
    ) {
      return METADATA;
    }
  }

  if (state.lettering) {
    if (!state.lettering.subjectDone) {
      if (stream.match(/sfx/i)) {
        state.lettering.subjectDone = true;
        return `${LETTERING_SUBJECT} ${LETTERING_LINE} ${SFX_LINE}`;
      } else if (stream.match(/caption/i)) {
        state.lettering.subjectDone = true;
        return `${LETTERING_SUBJECT} ${LETTERING_LINE} ${CAPTION_LINE}`;
      } else if (stream.match(/[:(]/)) {
        // looks like there's no subject
        stream.skipToEnd();
        state.exitLettering();
        return null;
      } else {
        state.lettering.subjectDone = true;

        const subject: Array<string> = [];

        // inch forward until getting to end of subject
        let eaten;
        /* eslint-disable-next-line no-cond-assign */
        while (eaten = stream.eat(/[^:(]/)) {
          subject.push(eaten);
        }

        // back track to get before any whitespace between subject and end of subject
        while (/\s/.test(subject[subject.length - 1])) {
          subject.pop();
          stream.backUp(1);
        }

        return `${LETTERING_SUBJECT} ${LETTERING_LINE} ${DIALOGUE_LINE}`;
      }
    }

    // haven't read modifier yet
    if (!state.lettering.modifierDone) {
      // consume any whitespace between subject and modifier
      if (stream.eatSpace()) return null;

      // next char is colon, there was no modifier
      if (stream.peek() === ':') {
        state.lettering.modifierDone = true;
      }
      // modifier has been opened, we're reading modifier text right now
      else if (state.lettering.inModifier) {
        if (stream.eatWhile(/[^:)]/)) {
          return LETTERING_MODIFIER;
        }
        // modifier is unclosed or empty
        else if (stream.eat(/[:)]/)) {
          state.lettering.inModifier = false;
          state.lettering.modifierDone = true;
          return null;
        }
        // modifier never ends
        else {
          stream.skipToEnd();
          state.lettering.inModifier = false;
          state.lettering.modifierDone = true;
          return null;
        }
      }
      // modifier not open yet but we know it's there, in some form
      else {
        stream.eat('(');
        state.lettering.inModifier = true;
        return null;
      }
    }

    if (!state.lettering.contentDone) {
      if (state.lettering.inContent) {
        const styles = tokenLetteringText(stream);

        if (stream.eol()) {
          state.lettering.contentDone = true;
          state.lettering.inContent = false;
        }

        return styles;
      } else if (stream.skipTo(':')) {
        stream.next();
        stream.eatSpace();

        state.lettering.inContent = true;
        return null;
      } else {
        stream.skipToEnd();
        state.lettering.contentDone = true;
        return null;
      }
    }
  }

  // getting this far means we're in a paragraph
  return tokenParagraphText(stream, state);
}

function tokenLetteringText(stream: StringStream): string {
  const tokens = [LETTERING_CONTENT];

  // stream is currently at double star
  if (stream.match(AT_BOLD_STARS)) {
    // and there is another double star somewhere
    if (stream.match(UP_TO_BOLD_STARS)) {
      // that was a run of bold
      tokens.push(LETTERING_BOLD);
    } else { // stream is at an unpaired double star
      // the rest of the line is regular text
      stream.skipToEnd();
    }
  } else { // stream isn't at double star right now
    const streamMoved = stream.eatWhile(definitelyNotBold);

    // Tried to advance to next star but didn't move -- stream was already at a star
    if (!streamMoved) {
      // It's safe to blindly eat (length of bold prefix) chars because the
      // outer if would've hit if the bold prefix was there.
      eatChars(stream, boldPrefixLength());

      // Now we're in uncharted territory, move to next thing that could be bold
      stream.eatWhile(definitelyNotBold);
    }
  }

  return tokens.join(' ');
}

function tokenParagraphText(stream: StringStream, state: State): string {
  const tokens = [PARAGRAPH];

  if (stream.match(AT_BOLD_STARS)) {
    const urlMatch = stream.match(UP_TO_URL, false) as RegExpMatchArray | null;
    const starsMatch = stream.match(UP_TO_BOLD_STARS, false) as RegExpMatchArray | null;

    // url found but no closing stars
    if (urlMatch && !starsMatch) {
      // consume plain text up until url
      stream.match(urlMatch[1]);
    }
    // no url but there are closing stars
    else if (!urlMatch && starsMatch) {
      stream.match(UP_TO_BOLD_STARS);
      tokens.push(PARAGRAPH_BOLD);
    }
    // url and closing stars exist, see what's first
    else if (urlMatch && starsMatch) {
      const urlPos = urlMatch[1].length;
      const starsPos = starsMatch[1].length;

      // it was a run of bold, url comes after bold stars are closed
      if (starsPos < urlPos) {
        stream.match(UP_TO_BOLD_STARS);
        tokens.push(PARAGRAPH_BOLD);
      } else {
        // consume bold chars up until start of url
        stream.match(urlMatch[1]);
        tokens.push(PARAGRAPH_BOLD);

        // leave a reminder to finish the bold after the url
        state.inUnfinishedParagraphBold = true;
      }
    } else {
      // no url and no closing stars, everything else is plain text
      stream.skipToEnd();
    }
  } else if (stream.match(AT_URL)) {
    tokens.push(PARAGRAPH_URL);

    if (state.inUnfinishedParagraphBold) {
      tokens.push(PARAGRAPH_BOLD);
    }
  } else if (state.inUnfinishedParagraphBold) {
    // look for the ending
    stream.match(UP_TO_BOLD_STARS);
    tokens.push(PARAGRAPH_BOLD);
  } else {
    const streamMoved = stream.eatWhile(definitelyNotBoldOrUrl);

    // Tried to advance to star or h but was already at one
    if (!streamMoved) {
      // Stream is at * or h but it's not part of ** nor http because earlier
      // if-statements would have handled it. This means the next few chars are
      // plain text.
      eatChars(stream, boldOrUrlPrefixLength());

      // Now we're in uncharted territory, move to the next thing that could be
      // bold or a url
      stream.eatWhile(definitelyNotBoldOrUrl);
    }
  }

  return tokens.join(' ');
}

function definitelyNotBoldOrUrl(char: string): boolean {
  return definitelyNotBold(char) && char !== FIRST_CHAR_URL;
}

function definitelyNotBold(char: string): boolean {
  return char !== FIRST_CHAR_BOLD_STARS;
}

/**
 * @param count How many chars to eat
 */
function eatChars(stream: StringStream, count: number) {
  stream.eatWhile(() => count-- !== 0);
}

function boldOrUrlPrefixLength() {
  return Math.min(boldPrefixLength(), 'http://'.length);
}

function boldPrefixLength() {
  return '**'.length;
}
