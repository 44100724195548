import Modal from '../../../components/modal/Modal';
import Button from '../../../components/button/Button';
import ToolBar from '../../../components/tool-bar/ToolBar';

export interface MessageBoxProps {
  title: string;
  message: string;
  /**
   * Labels for buttons that perform destructive actions, e.g. Discard Changes.
   */
  unsafeButtons?: Array<string>;
  /**
   * Button labels.
   *
   * Primary action should always be the right-most button.
   * Cancel should be immediately to the left of the primary action.
   */
  buttons: Array<string>;
  /**
   * Button index to put initial focus on.
   *
   * The first unsafe button (if any) is index 0. Regular buttons come after
   * the unsafe buttons.
   */
  defaultIndex: number;
  /**
   * When dialog is closed without selecting a button, this index is returned.
   *
   * The first unsafe button (if any) is index 0. Regular buttons come after
   * the unsafe buttons.
   */
  cancelIndex: number;
  /**
   * Invoked when a button is selected (or modal is canceled).
   *
   * The first unsafe button (if any) is index 0. Regular buttons come after
   * the unsafe buttons.
   *
   * @param index - Index of selected action.
   */
  onResult: (index: number) => void;
}

/**
 * A basic dialog that renders some buttons and reports back on which button
 * was selected.
 *
 * Inspired by electron's dialog.showMessageBox().
 *
 * Roughly follows Apple's dialog guidelines:
 * https://developer.apple.com/design/human-interface-guidelines/macos/windows-and-views/dialogs/
 */
export const MessageBox = (props: MessageBoxProps) => {
  const buttonId = (index: number) => `message-box-button-${index}`;
  const renderButtons = (labels: Array<string>, indexOffset: number) => {
    return labels.map((label, index) => (
      <Button
        primary={props.defaultIndex === index + indexOffset}
        key={String(index + indexOffset)}
        id={buttonId(index + indexOffset)}
        onClick={() => props.onResult(index + indexOffset)}
      >
        {label}
      </Button>
    ));
  }

  return (
    <Modal
      title={props.title}
      initialFocus={`#${buttonId(props.defaultIndex)}`}
      closeButtonOnClick={() => props.onResult(props.cancelIndex)}
    >
      <p className="c-message-box__message">{props.message}</p>
      <ToolBar>
        {renderButtons(props.unsafeButtons || [], 0)}
        <></>
        {renderButtons(props.buttons, props.unsafeButtons ? props.unsafeButtons.length : 0)}
      </ToolBar>
    </Modal>
  );
};
