/**
 * If true, the browser only supports the file system access workarounds which
 * are:
 *
 * - `a[download=filename]` for saving
 * - `input[type=file]` for opening
 *
 * Also when this is true there is no difference between 'Save' and 'Save As',
 * both will act like 'Save As'.
 */
export const isLegacyFileSystem = !(
  // modern api (latest chromium)
  'showOpenFilePicker' in window
  // early draft of modern api (older chromium)
  || 'chooseFileSystemEntries' in window
);