import { fileOpen } from 'browser-fs-access';
import {
  dialogSuccess,
  dialogCancel,
  isCancel,
} from './dialog-outcome';
import { FileDialogOutcome } from './dialog-outcome';

// This is copy/pasted out of browser-nativefs
export interface OpenFileOptions {
  /** Acceptable MIME types. [] */
  mimeTypes?: string[];
  /** Acceptable file extensions. Defaults to "". */
  extensions?: string[];
  /** Suggested file description. Defaults to "". */
  description?: string;
}

export type OpenFileOutcome = FileDialogOutcome<File>;

export async function openFile(options: OpenFileOptions = {}): Promise<OpenFileOutcome> {
  try {
    const file = await fileOpen({
      ...options,
      multiple: false
    });

    return dialogSuccess(file);
  } catch (error) {
    if (isCancel(error)) {
      return dialogCancel();
    } else {
      throw error;
    }
  }
}
