import { useStore } from '../../store/use-store';
import { observer } from 'mobx-react-lite';
import { MessageBox } from './message-box/MessageBox';
import { OnBoarding } from './onboarding/OnBoarding';
import { Settings } from './settings/Settings';
import { About } from './about/About';

/**
 * Every modal in the app is rendered through this component.
 */
export const ModalOutlet = observer(() => {
  const { modals } = useStore();

  return modals.messageBox ? <MessageBox {...modals.messageBox} />
    : modals.onBoarding ? <OnBoarding {...modals.onBoarding} />
    : modals.settings ? <Settings {...modals.settings} />
    : modals.about ? <About {...modals.about} />
    : null;
});
