import { SpreadLines } from '../parser/nodes';
import { isSpread } from '../parser';

interface SourceChunks {
  /** All lines */
  lines: Array<string>;
  /** Lines before first spread */
  preSpreadLines: Array<string>;
  /** Lines of each spread */
  spreadLines: Array<SpreadLines>;
}

/**
 * Breaks script source into chunks of lines:
 *
 * - pre-spread lines
 * - lines that comprise each spread
 */
export function chunk(source: Array<string> | string): SourceChunks {
  const lines = Array.isArray(source) ? source : source.split('\n');
  const chunks = [...spreadDelimitedChunks(lines)];

  return {
    lines,
    preSpreadLines: chunks[0],
    spreadLines: chunks.slice(1).map(chunk => ({
      spread: chunk[0],
      children: chunk.slice(1)
    }))
  };
}

function* spreadDelimitedChunks(lines: Array<string>) {
  let chunk: Array<string> = [];

  for (let i = 0; i < lines.length; i++) {
    if (isSpread(lines[i])) {
      yield chunk;
      chunk = [];
    }

    chunk.push(lines[i]);
  }

  yield chunk;
}
