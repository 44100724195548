export function wrapRange(
  line: string,
  rangeStart: number,
  rangeEnd: number,
  front: string,
  back: string
): string {
  const beforeRange = line.slice(0, rangeStart);
  const inRange     = line.slice(rangeStart, rangeEnd);
  const afterRange  = line.slice(rangeEnd);

  return [
    beforeRange,
    front,
    inRange,
    back,
    afterRange
  ].join('');
}
