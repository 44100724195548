import Routes from '../Routes';
import { ModalOutlet } from '../modals';
import { Toaster } from 'react-hot-toast';

/** The root of the visual components that make up the app. */
export default function Root() {
  return (
    <>
      <Routes />
      <ModalOutlet />
      <Toaster position="bottom-right" />
    </>
  );
}
