import React from 'react';
import './SplitView.css';

import Split, { SplitProps } from 'react-split';

type Props = Pick<SplitProps, 'direction' | 'sizes' | 'minSize' | 'expandToMin' | 'snapOffset' | 'dragInterval' | 'cursor' | 'children'>

const SplitView = (props: Props) =>
  <Split className="isola-c-split-view"
    sizes={props.sizes ? props.sizes : [10, 90]}
    minSize={props.minSize ? props.minSize : 100}
    expandToMin={props.expandToMin ? props.expandToMin : false}
    gutterSize={5}
    gutterAlign="center"
    snapOffset={props.snapOffset ? props.snapOffset : 20}
    dragInterval={props.dragInterval ? props.dragInterval : 10}
    direction={props.direction ? props.direction : "horizontal"}
    cursor={props.cursor ? props.cursor : "col-resize"}
  >
    {React.Children
      .toArray(props.children)
      .slice(0,2) // Force only a max of 2 children.
      .map((a, i) =>
        i === 0 ?
          <div className="c-split-view__sidebar" key={i}>{a}</div> :
          <div key={i}>{a}</div>
      )}
  </Split>;

export {
  SplitView
}
