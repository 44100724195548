import { Editor } from 'codemirror';
import { findSubject, findModifier, findContent, selectTokens } from './token-helpers';

import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/show-hint.css';

export type TabStop = {
  /** Human-readable name for debugging purposes. */
  name: string;
  /**
   * Called when entering this tab stop.
   */
  activate: (cm: Editor) => void;
  /**
   * Optional. Called when exiting this tab stop.
   */
  deactivate?: (cm: Editor) => void;
};

/**
 * Tab stop for the content token of all lettering types.
 */
const commonContentTabStop: TabStop = {
  name: 'content',
  activate(cm) {
    const cursor = cm.getCursor();
    const contentTokens = findContent(cm.getLineTokens(cursor.line));

    if (contentTokens.length > 0) {
      selectTokens(cm, cursor.line, ...contentTokens);
    }
  }
}

/**
 * Make tab stops for a lettering type where the subject is not user editable,
 * i.e. caption and sfx.
 *
 * @param subject
 * @param origin
 */
export function createStaticSubjectTabStops(subject: string, origin: string): Array<TabStop> {
  return [
    {
      name: 'modifier',
      activate(cm) {
        const cursor = cm.getCursor();
        const modifier = findModifier(cm.getLineTokens(cursor.line));

        if (modifier) {
          selectTokens(cm, cursor.line, modifier);
        } else {
          const originalText = cm.getLine(cursor.line);
          const withParens = originalText.replace(`${subject}:`, `${subject} ():`);

          cm.replaceRange(withParens, {
            line: cursor.line,
            ch: 0
          }, {
            line: cursor.line,
            ch: originalText.length + 1
          }, origin);

          cm.setSelection({
            line: cursor.line,
            ch: withParens.indexOf(')')
          },
            undefined,
            {
              origin: origin
            });
        }
      },
      deactivate(cm) {
        const cursor = cm.getCursor();
        const modifier = findModifier(cm.getLineTokens(cursor.line));

        // delete the ' ()' if there is no modifier
        if (!modifier) {
          const originalText = cm.getLine(cursor.line);
          const withoutParens = originalText.replace(' ()', '');

          cm.replaceRange(withoutParens, {
            line: cursor.line,
            ch: 0
          }, {
            line: cursor.line,
            ch: originalText.length + 1
          });
        }
      }
    },
    commonContentTabStop
  ];
}

/**
 * Make tab stops for dialogue lettering snippet.
 *
 * @param getCharacterNames
 */
export function createDialogueTabStops(getCharacterNames: () => Array<string>, defaultSubject: string): Array<TabStop> {
  let subjectActivations = 0;

  return [
    {
      name: 'subject',
      activate(cm) {
        subjectActivations += 1;

        const cursor = cm.getCursor();
        const subject = findSubject(cm.getLineTokens(cursor.line));

        if (subject) {
          selectTokens(cm, cursor.line, subject);
        }

        if (subjectActivations === 1) {
          cm.showHint({
            hint: makeHinter(getCharacterNames(), defaultSubject),
            // Don't auto select a single suggestion because use could be typing a
            // new character name
            completeSingle: false,
          });
        }
      }
    },
    {
      name: 'modifier',
      activate(cm) {
        const cursor = cm.getCursor();
        const modifier = findModifier(cm.getLineTokens(cursor.line));
        const lineText = cm.getLine(cursor.line);

        if (modifier) {
          selectTokens(cm, cursor.line, modifier);
        } else if (containsEmptyModifierParens(lineText)) {
          cm.setSelection({
            line: cursor.line,
            ch: lineText.indexOf(')')
          });
        } else {
          const originalText = lineText;
          const withParens = originalText.replace(/\t(.+):/, '\t$1 ():');

          cm.replaceRange(withParens, {
            line: cursor.line,
            ch: 0
          }, {
            line: cursor.line,
            ch: originalText.length + 1
          });

          cm.setSelection({
            line: cursor.line,
            ch: withParens.indexOf(')')
          });
        }
      },
      deactivate(cm) {
        const cursor = cm.getCursor();
        const modifier = findModifier(cm.getLineTokens(cursor.line));

        // delete the ' ()' if there is no modifier
        if (!modifier) {
          const originalText = cm.getLine(cursor.line);
          const withoutParens = originalText.replace(' ()', '');

          cm.replaceRange(withoutParens, {
            line: cursor.line,
            ch: 0
          }, {
            line: cursor.line,
            ch: originalText.length + 1
          });
        }
      }
    },
    commonContentTabStop
  ];
}


function containsEmptyModifierParens(line: string): boolean {
  return line.includes('():');
}

function makeHinter(characterNames: Array<string>, characterPlaceholder: string) {
  function hinter(cm: CodeMirror.Editor) {
    const cursor = cm.getCursor();
    const token = cm.getTokenAt(cursor);

    const currentTokenText = token.string.toLocaleUpperCase();

    const suggestions = characterNames
      .map(name => name.toLocaleUpperCase())
      .filter(name => {
        return currentTokenText === characterPlaceholder
          || name.startsWith(currentTokenText);
      });

    return {
      list: suggestions,
      from: {
        line: cursor.line,
        ch: token.start
      },
      to: {
        line: cursor.line,
        ch: token.end
      }
    };
  }

  // allow hint popup when text is selected
  hinter.supportsSelection = true;

  return hinter;
}
