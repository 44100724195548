import { PropsWithChildren } from 'react';
import { SpreadStat } from '../../insights/types';

import './BarGraph.css';

type BarProps = {
  /** Page numbers in this bar's spread. */
  pages: SpreadStat<number>['pages'],
  /** Value this bar reprsents */
  value: number;
  /** Height of the bar within its container */
  percent: number;
  /** How many digits after decimal point to show */
  decimalDigits: number;
  /** Invoked when user selects this bar */
  onSelection: (index: number) => void;
  /** Zero-based index of this bar's position among the other bars. */
  index: number;
  /** Should this bar be highlighted */
  highlight?: boolean;
  /** Invoked when user hovers over this bar */
  onHover: (index: number) => void;
  /** Line number this bar's spread starts on */
  lineNumber: number;
};

const Bar = (props: BarProps) => {
  const formattedValue = props.value !== 0
    ? props.value.toFixed(props.decimalDigits)
    : 0;

  const label = props.pages[0] + (props.pages.length === 1 ? '' : '…');

  return (
    <li
      className="c-bar-chart__bar-wrapper"
      onMouseEnter={() => props.onHover(props.index)}
    >
      <button
        className="c-bar-chart__bar-button"
        onClick={() => props.onSelection(props.lineNumber)}
      >
        <span
          className={`
            c-bar-chart__bar
            ${props.highlight ? 'c-bar-chart__bar--highlight' : ''}
          `}
          style={{ height: `${props.percent}%` }}
        >
          <span className="c-bar-chart__bar-label">{label}</span>
          <span className="c-bar-chart__value">{formattedValue}</span>
        </span>
      </button>
    </li>
  );
};

type BarGraphProps = {
  /** Data to visualize */
  stats: Array<SpreadStat<number>>;
  /** A valid css length */
  height: string;
  /** Label for the independent axis */
  xAxisLabel: string;
  /** Caption of this graph */
  caption: string;
  /** From 0 to 100 inclusive */
  chartPosition?: number;
  /**
   * How many bar value digits to show after decimal point. Defaults to 0.
   *
   * Should be
   *
   * - 0 when values are integers.
   * - 1 when values might not be whole numbers (e.g. averages).
   */
  decimalDigits?: number;
  /**
   * Invoked when a bar is clicked/operated on.
   */
  onBarSelection: (lineNumber: number) => void;
  /**
   * Index of the bar to highlight, if any.
   */
  highlightedIndex?: number | null;
  /**
   * Invoked when a bar's vertical column of space is hovered.
   */
  onBarHover: (index: number) => void;
};

export const BarGraph = (props: BarGraphProps) => {
  const yMax = yAxisUpperBound(props.stats);

  return (
    <figure className="c-bar-chart u-font-size--saya">
      <div className="c-bar-chart__wrapper">
        <BarBackground height={props.height}>
          {yAxisTickLines(tickCount(yMax))}
        </BarBackground>
        <ul
          className="c-bar-chart__graph"
          style={{ height: props.height }}
        >
          {props.stats.map(stat =>
            <Bar
              key={stat.pages[0]}
              pages={stat.pages}
              value={stat.value}
              percent={(stat.value / yMax) * 100}
              decimalDigits={props.decimalDigits ?? 0}
              onSelection={props.onBarSelection}
              index={stat.index}
              highlight={props.highlightedIndex === stat.index}
              onHover={props.onBarHover}
              lineNumber={stat.lineNumber}
            />
          )}
        </ul>
        {props.chartPosition != null && <div
          className="c-bar-chart__chart-position"
          style={{ left: `${props.chartPosition}%` }}
        />}
      </div>

      <div className="c-bar-chart__x-axis-label">{props.xAxisLabel}</div>
      <figcaption className="u-hide--visually">{props.caption}</figcaption>
    </figure>
  );
}

type BackgroundProps = PropsWithChildren<{
  /** A valid css length */
  height: BarGraphProps['height'];
}>;

function BarBackground(props: BackgroundProps) {
  return (
    <div
      className="c-bar-chart__bar-background"
      style={{ height: props.height }}
    >
      {props.children}
    </div>
  );
}

function yAxisTickLines(count: number) {
  const step = 100 / count;

  return Array(count).fill(null).map((_, index) => {
    return (
      <div
        key={index}
        className="c-bar-chart__y-axis-tick"
        style={{
          bottom: `${index * step + step}%`
        }}
      />
    );
  });
}

/**
 * Calculates a decent value for the top of the y axis range.
 */
function yAxisUpperBound(stats: Array<SpreadStat<number>>): number {
  const maxStatValue = Math.max(...stats.map(s => s.value));

  return maxStatValue < 10 ? Math.max(4, maxStatValue)
    : maxStatValue < 20 ? nearestMultiple(maxStatValue, 2)
    : maxStatValue < 50 ? nearestMultiple(maxStatValue, 5)
    : nearestMultiple(maxStatValue, 10);
}

function nearestMultiple(value: number, step: number): number {
  return Math.ceil(value / step) * step;
}

/**
 * How many y-axis ticks would be good.
 *
 * @param upperBound Max value of the y-axis range
 */
function tickCount(upperBound: number): number {
  return upperBound < 10
    ? Math.max(4, upperBound)
    : 10;
}
