import { saveFile, SaveFileOutcome } from './save-file';

const EXTENSION = 'pdf';
const DEFAULT_ROOT_FILENAME = 'untitled';

export type SavePdfOutcome = SaveFileOutcome;

export function savePdf(blob: Blob, suggestedRootFilename = DEFAULT_ROOT_FILENAME): Promise<SavePdfOutcome> {
  return saveFile(blob, {
    fileName: `${suggestedRootFilename}.${EXTENSION}`,
    extensions: [`.${EXTENSION}`]
  });
}
