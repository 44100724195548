import { saveFile, SaveFileOutcome } from './save-file';

const EXTENSION = 'docx';
const DEFAULT_ROOT_FILENAME = 'untitled';

export type SaveDocxOutcome = SaveFileOutcome;

/**
 * @param blob docx data
 * @param suggestedRootFilename Optional filename without extension.
 */
export function saveDocx(blob: Blob, suggestedRootFilename = DEFAULT_ROOT_FILENAME): Promise<SaveDocxOutcome> {
  return saveFile(blob, {
    fileName: `${suggestedRootFilename}.${EXTENSION}`,
    extensions: [`.${EXTENSION}`]
  });
}
