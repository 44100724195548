/**
 * The outcome of a Save/Open file dialog.
 */
export type FileDialogOutcome<SuccessResult> =
  DialogSuccess<SuccessResult> | DialogCancel;

export type DialogCancel = {
  type: 'cancel'
};

export type DialogSuccess<Result> = {
  type: 'success',
  result: Result
};

export function dialogSuccess<Result>(result: Result): DialogSuccess<Result> {
  return {
    type: 'success',
    result
  };
}

export function dialogCancel(): DialogCancel {
  return {
    type: 'cancel'
  };
};

/**
 * Determine if an error means the user canceled the file dialog. Only works
 * when using the File Access API.
 */
export const isCancel = isAbortError;

function isAbortError(error: Error): boolean {
  return isDomException(error, DOMException.ABORT_ERR);
}

function isSecurityError(error: Error): boolean {
  return isDomException(error, DOMException.SECURITY_ERR);
}

/**
 * Determine if error looks like the "...must be triggered by user gesture"
 * thing seen in issue 72.
 *
 * @param error
 */
export function isGestureError(error: Error): boolean {
  return isSecurityError(error) && error.message?.includes('gesture');
}

function isDomException(error: Error, errorCode: number): boolean {
  return error instanceof DOMException && error.code === errorCode;
}
