import {
  observable,
  computed,
  action,
  makeObservable,
} from 'mobx';

import {
  PreSpread,
  SpreadLines,
  Spread,
} from '../parser/nodes';
import { parsePreSpreadLines, parseSpreadLines } from '../parser';
import * as iterators from './iterator';
import * as parts from '../comic-part-types';
import { chunk as chunkSource } from './chunk';
import { Sequence } from '../util/sequence';
import { NodeIds } from './node-ids';
import { createMemoizedMapper } from './memoized-mapper';

import {
  PanelCount,
  WordCount,
  FullScript,
} from './types';
import * as deepEquals from '../util/deep-equals';
import { end, start } from '../perf';

/**
 *
 * Important: Anything in this class that should be enhanced by mobx must also
 * be part of the decorate block below the class definition.
 */
export class ScriptStore {
  /**
   * Raw script content.
   */
  source: string;
  preSpread: Array<string>;
  spreads: Array<SpreadLines>;

  constructor() {
    makeObservable(this, {
      // observables
      source: observable,
      preSpread: observable.ref,
      spreads: observable.ref,

      // computeds
      parsedSpreads: computed,
      parsedPreSpreadNodes: computed,
      preSpreadLineCount: computed,
      locatedSpreads: computed,
      fullScript: computed,
      speakers: computed({
        equals: deepEquals.strings
      }),
      panelCounts: computed({
        equals: deepEquals.panelCounts,
      }),
      wordCounts: computed({
        equals: deepEquals.wordCounts
      }),

      // actions
      updateScript: action,
    });

    this.source = '';
    this.preSpread = [];
    this.spreads = [];
  }

  // helper for reusing spread parse results
  private spreadParser = createMemoizedMapper<SpreadLines, Spread>(
    rawChunk => parseSpreadLines(rawChunk)
  );

  // computed values

  get parsedSpreads(): Array<Spread> {
    return this.spreadParser(this.spreads);
  }

  get parsedPreSpreadNodes(): Array<PreSpread> {
    return parsePreSpreadLines(this.preSpread);
  }

  get preSpreadLineCount(): number {
    return this.preSpread.length;
  }

  get locatedSpreads(): Array<Spread> {
    start('locatedSpreads');

    let pageNumber = 1;
    const lineNumbers = new Sequence(this.preSpreadLineCount);
    const nodeIds = new NodeIds();

    const results = this.parsedSpreads.map(spread => {
      const located = spread.toLocated(nodeIds, lineNumbers, pageNumber);

      // advance page number to next available page
      pageNumber += spread.pageCount;

      return located;
    });

    end('locatedSpreads');

    return results;
  }

  get locatedPreSpreadNodes(): Array<PreSpread> {
    const lineNumbers = new Sequence(0);
    const nodeIds = new NodeIds('pre-');

    return this.parsedPreSpreadNodes
      .map(node => {
        const copy = node.shallowCopy();
        copy.id = nodeIds.next(node.type);
        copy.lineNumber = lineNumbers.next();
        return copy;
      });
  }

  get fullScript(): FullScript {
    return {
      preSpread: this.locatedPreSpreadNodes,
      spreads: this.locatedSpreads
    };
  }

  get speakers(): Array<string> {
    const speakers = new Set<string>();

    for (const spread of this.locatedSpreads) {
      for (const speaker of spread.speakers) {
        speakers.add(speaker.toUpperCase());
      }
    }

    return [...speakers].sort();
  }

  get panelCounts(): Array<PanelCount> {
    return this.locatedSpreads
      .map(spread => ({
        lineNumber: spread.lineNumber,
        count: spread.panelCount
      }));
  }

  get wordCounts(): Array<WordCount> {
    const wordCounts: Array<WordCount> = [];

    for (const node of iterators.spreadsAndChildren(this.locatedSpreads)) {
      if (
        node.type === parts.DIALOGUE ||
        node.type === parts.CAPTION ||
        node.type === parts.PANEL ||
        node.type === parts.SPREAD
      ) {
        wordCounts.push({
          count: node.wordCount,
          lineNumber: node.lineNumber,
          isSpread: node.type === parts.SPREAD
        });
      }
    }

    return wordCounts;
  }

  // actions

  /**
   * Update script, using previous script value as a reference point.
   */
  updateScript(script: Array<string>): void {
    const {
      lines,
      preSpreadLines,
      spreadLines,
    } = chunkSource(script);

    this.updateSource(lines);
    this.updatePreSpread(this.preSpread, preSpreadLines);
    this.updateSpreads(this.spreads, spreadLines);
  }

  /**
   * Replace script, not caring about what the script was before.
   */
  replaceScript(script: string): void {
    const {
      lines,
      preSpreadLines,
      spreadLines,
    } = chunkSource(script);

    this.updateSource(lines);
    this.updatePreSpread(this.preSpread, preSpreadLines);
    this.updateSpreads(this.spreads, spreadLines);
  }

  // private helpers

  private updateSource(lines: Array<string>): void {
    this.source = lines.join('\n');
  }

  private updatePreSpread(current: Array<string>, incoming: Array<string>): void {
    this.preSpread = deepEquals.strings(current, incoming)
      ? current
      : incoming;
  }

  private updateSpreads(current: Array<SpreadLines>, incoming: Array<SpreadLines>): void {
    let changes = 0;
    const nextSpreads: Array<SpreadLines> = [];

    const length = Math.max(current.length, incoming.length);
    for (let i = 0; i < length; i++) {
      const currentSpread = current[i];
      const incomingSpread = incoming[i];

      let nextSpread;

      if (deepEquals.spreadLines(currentSpread, incomingSpread)) {
        nextSpread = currentSpread;
      } else {
        nextSpread = incomingSpread;
        changes += 1;
      }

      if (nextSpread) {
        nextSpreads.push(nextSpread);
      }
    }

    if (changes > 0) {
      this.spreads = nextSpreads;
    }
  }
}
