import * as perf from '../perf';
import * as parts from '../comic-part-types';
import countWords from './count-words';
import * as classifiers from './line-classifiers';
import {
  Spread,
  Panel,
  Paragraph,
  Metadata,
  Dialogue,
  Caption,
  Sfx,
  TextChunk,
  BlankLine,
  PreSpread,
  SpreadChild,
  SpreadLines,
} from './nodes';

import {
  SPREAD_REGEX,
  PANEL_REGEX,
  CAPTION_REGEX,
  SFX_REGEX,
  DIALOGUE_REGEX,
  METADATA_REGEX,
  LETTERING_BOLD_REGEX,
  URL_REGEX,
  startsWithUrlPrefix,
} from './regexes';
import { Sequence } from '../util/sequence';

// flyweight pattern: reuse this to represent every blank line
const BLANK_LINE = new BlankLine();

export const parseSpreadLines = perf.wrap('parseSpreadLines', parse);

function parse(lines: SpreadLines): Spread {
  const spread = parseSpread(lines.spread);

  for (const child of parseSpreadChildren(lines.children)) {
    spread.addChild(child);
  }

  return spread;
}

function parseSpreadChildren(lines: Array<string>): Array<SpreadChild> {
  const letteringNumbering = new Sequence(1);

  const spreadChildren: Array<SpreadChild> = [];
  let currentPanel: Panel | null = null;

  for (let i = 0; i < lines.length; i++) {
    const parsed = parseSpreadChild(lines[i], letteringNumbering);

    if (parsed.type === parts.PANEL) {
      currentPanel = parsed;
      spreadChildren.push(parsed);
    } else {
      if (currentPanel) {
        currentPanel.addChild(parsed);
      } else {
        spreadChildren.push(parsed);
      }
    }
  }

  return spreadChildren;
}

function parseSpreadChild(line: string, letteringNumbering: Sequence): SpreadChild {
  // scripts are about 1/2 blank lines so this should be first
  if (classifiers.isBlank(line)) return BLANK_LINE;

  if (classifiers.isCaption(line)) return parseCaption(line, letteringNumbering);
  if (classifiers.isSfx(line)) return parseSfx(line, letteringNumbering);
  // dialogue has to be checked after sfx/caption, otherwise we get balloons
  // where the speaker is "caption" and "sfx"
  if (classifiers.isDialogue(line)) return parseDialogue(line, letteringNumbering);

  if (classifiers.isPanel(line)) return parsePanel(line);

  // any non-blank line can be a paragraph so it goes last
  return parseParagraph(line);
}

export function parsePreSpreadLines(lines: Array<string>): Array<PreSpread> {
  return lines.map(line => parsePreSpreadLine(line));
}

function parsePreSpreadLine(line: string): PreSpread {
  // scripts are about 1/2 blank lines so this should be first
  if (classifiers.isBlank(line)) return BLANK_LINE;

  if (classifiers.isMetadata(line)) return parseMetadata(line);

  // any non-blank line can be a paragraph so it goes last
  return parseParagraph(line);
}

export function parseSpread(line: string): Spread {
  const matchResult = SPREAD_REGEX.exec(line) as Array<string>;

  const startPage = Number(matchResult[1]);
  const endPage = matchResult[3] != null ? Number(matchResult[3]) : startPage;
  const pageCount = countPages(startPage, endPage);

  const spread = new Spread();
  spread.pageCount = pageCount;
  spread.startPage = startPage;
  return spread;
}

function countPages(startPage: number, endPage?: number): number {
  if (endPage == null) {
    return 1;
  } else if (startPage < endPage) {
    return (endPage - startPage) + 1;
  } else if (startPage > endPage) {
    return 2;
  } else { // startPage === endPage
    return 1;
  }
}

export function parsePanel(line: string): Panel {
  const [, number] = PANEL_REGEX.exec(line) as Array<string>;

  const panel = new Panel();
  panel.number = Number(number);
  return panel;
}

export function parseMetadata(line: string): Metadata {
  const [, name, value] = METADATA_REGEX.exec(line) as Array<string>;

  return new Metadata(name, value);
}

export function parseParagraph(line: string): Paragraph {
  const paragraph = new Paragraph();
  paragraph.content = parseParagraphContent(line);
  return paragraph;
}

export function parseDialogue(line: string, numbering: Sequence): Dialogue {
  const [, speaker, modifier, content] = DIALOGUE_REGEX.exec(line) as Array<string>;

  const parseTree = parseLetteringContent(content);

  const dialogue = new Dialogue();
  dialogue.number = numbering.next();
  dialogue.speaker = speaker;
  dialogue.modifier = normalizeModifier(modifier);
  dialogue.content = parseTree;
  dialogue.wordCount = countWords(parseTree);
  return dialogue;
}

export function parseCaption(line: string, numbering: Sequence): Caption {
  const [, modifier, content] = CAPTION_REGEX.exec(line) as Array<string>;
  const parseTree = parseLetteringContent(content);

  const caption = new Caption();
  caption.number = numbering.next();
  caption.modifier = normalizeModifier(modifier);
  caption.content = parseTree;
  caption.wordCount = countWords(parseTree);
  return caption;
}

export function parseSfx(line: string, numbering: Sequence): Sfx {
  const [, modifier, content] = SFX_REGEX.exec(line) as Array<string>;

  const sfx = new Sfx();
  sfx.number = numbering.next();
  sfx.modifier = normalizeModifier(modifier);
  sfx.content = parseLetteringContent(content);
  return sfx;
}

function normalizeModifier(modifier: string | null): string {
  return modifier ? modifier.slice(1, -1) : '';
}

function parseParagraphContent(content: string): Array<TextChunk> {
  const chunks: Array<TextChunk> = [];

  let cursor = 0;
  let execResult = null;

  // eslint-disable-next-line no-cond-assign
  while (execResult = LETTERING_BOLD_REGEX.exec(content.slice(cursor))) {
    // Add text seen before the match, if any
    const before = content.slice(cursor, cursor + execResult.index)
    if (before) {
      chunks.push(...splitByUrls(before, parts.TEXT));
    }

    // add the match
    chunks.push(...splitByUrls(execResult[1], parts.BOLD_TEXT));

    // advance cursor to unmatched part
    cursor += execResult.index + execResult[0].length;
  }

  // add text seen after last match, if any
  const after = content.slice(cursor);
  if (after) {
    chunks.push(...splitByUrls(after, parts.TEXT));
  }

  return chunks;
}

function splitByUrls(content: string, nonUrlType: parts.NonUrlText): Array<TextChunk> {
  // regex must have capture group around the entire url or this breaks
  return content.split(URL_REGEX)
    .filter(content => content)
    .map(content => new TextChunk(
      startsWithUrlPrefix(content) ? parts.URL_TEXT : nonUrlType,
      content
    ));
}

function parseLetteringContent(content: string): Array<TextChunk> {
  const chunks: Array<TextChunk> = [];

  let index = 0;
  let result = null;

  // eslint-disable-next-line no-cond-assign
  while (result = LETTERING_BOLD_REGEX.exec(content.slice(index))) {
    const before = content.slice(index, index + result.index)

    if (before) {
      chunks.push(TextChunk.plain(before));
    }

    chunks.push(TextChunk.bold(result[1]));

    index += result.index + result[0].length;
  }

  const after = content.slice(index);
  if (after) {
    chunks.push(TextChunk.plain(after));
  }

  return chunks;
}
