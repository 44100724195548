import { useCallback, useMemo } from 'react';
import { useCodeMirror } from '../hooks/use-code-mirror';
import { create } from './word-count-updater';
import { EMPTY_GUTTER, WORD_COUNTS_GUTTER } from './gutter-ids';
import { useStore } from '../../../../store';
import { Editor } from 'codemirror';
import { autorun } from 'mobx';

export function WordCounts() {
  // Install word count gutter into CM
  useCodeMirror(useCallback(editor => {
    editor.setOption('gutters', [WORD_COUNTS_GUTTER]);
    return () => editor.setOption('gutters', [EMPTY_GUTTER]);
  }, []));

  const updater = useMemo(create, [create]);
  const { script } = useStore();

  // Update gutter when word counts change
  useCodeMirror(useCallback((editor: Editor) => {
    const dispose = autorun(() => updater(editor, script.wordCounts));
    return () => dispose();
  }, [script, updater]));

  return null;
}
