import { SpreadLines } from '../parser/nodes';
import { PanelCount, WordCount } from '../script/types';
import {
  OutlineItem,
  SpreadOutlineItem,
  PanelOutlineItem,
} from '../editor/types';

/*
 * Custom deep-equals implementations for various things.
 *
 * We use old school for loops here for speed.
 */

export function strings(a: Array<string>, b: Array<string>): boolean {
  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }

  return true;
}

export function spreadLines(chunkA: SpreadLines, chunkB: SpreadLines): boolean {
  if (chunkA == null) return chunkB == null;
  if (chunkB == null) return chunkA == null;

  if (chunkA.spread !== chunkB.spread) {
    return false;
  }

  return strings(chunkA.children, chunkB.children);
}

export function panelCounts(countsA: Array<PanelCount>, countsB: Array<PanelCount>): boolean {
  if (countsA == null) return countsB == null;
  if (countsB == null) return countsA == null;

  if (countsA.length !== countsB.length) {
    return false;
  }

  for (let i = 0; i < countsA.length; i++) {
    const oldCount = countsA[i];
    const newCount = countsB[i];

    if (oldCount.count !== newCount.count) return false;
    if (oldCount.lineNumber !== newCount.lineNumber) return false;
  }

  return true;
}

export function wordCounts(countsA: Array<WordCount>, countsB: Array<WordCount>): boolean {
  if (countsA == null) return countsB == null;
  if (countsB == null) return countsA == null;

  if (countsA.length !== countsB.length) {
    return false;
  }

  for (let i = 0; i < countsA.length; i++) {
    const countA = countsA[i];
    const countB = countsB[i];

    if (countA.count !== countB.count) return false;
    if (countA.lineNumber !== countB.lineNumber) return false;
    if (countA.isSpread !== countB.isSpread) return false;
  }

  return true;
}

export function outlineItems(
  itemsA: Array<OutlineItem>,
  itemsB: Array<OutlineItem>
): boolean {
  if (itemsA.length !== itemsB.length) return false;

  for (let i = 0; i < itemsA.length; i++) {
    const itemA = itemsA[i];
    const itemB = itemsB[i];

    if (itemA.type === 'panel' && itemB.type === 'panel') {
      if (!panelOutlineItem(itemA, itemB)) return false;
    } else if (itemA.type === 'spread' && itemB.type === 'spread') {
      if (!spreadOutlineItem(itemA, itemB)) return false;
    } else {
      return false;
    }
  }

  return true;
}

function spreadOutlineItem(itemA: SpreadOutlineItem, itemB: SpreadOutlineItem): boolean {
  return itemA.current === itemB.current
    && itemA.lineNumber === itemB.lineNumber
    && itemA.label === itemB.label
    && itemA.id === itemB.id;
}

function panelOutlineItem(itemA: PanelOutlineItem, itemB: PanelOutlineItem): boolean {
  return itemA.description === itemB.description
    && itemA.current === itemB.current
    && itemA.lineNumber === itemB.lineNumber
    && itemA.panelNumber === itemB.panelNumber
    && itemA.id === itemB.id;
}
