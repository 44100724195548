import { forDisplay } from '../../util/keyboard';
import './Hotkey.css';
// 'Cmd-B'
// 'Ctrl-B

const parseHotkeyToJsx = (keyCombo: Array<string>) =>
    forDisplay(keyCombo)
    // make "keys".
    .map((key, i) => <span key={i} className="c-hotkey__key">{key}</span>) // Decorate with JSX
    // insert + JSX between the fence posts, except for the last
    .reduce((result, key, index, arr) =>
      result.concat(
        key,
        (index !== arr.length - 1) && <span key={index + 10}>+</span>
      ),
      [] as Array<JSX.Element | boolean>
    )

type HotkeyProps = {
  keyCombo: Array<string>;
  animated?: boolean;
};

const Hotkey = (props: HotkeyProps) =>
  <div className={`
    c-hotkey
    ${props.animated ? 'c-hotkey--animated' : ''}
  `}>
    {parseHotkeyToJsx(props.keyCombo)}
  </div>;

export {
  Hotkey
}

// ⌘
// Ctrl
// Alt
