import './Writer.css';

import { RouteComponentProps } from '@reach/router';
import { observer } from 'mobx-react-lite';

import { SplitView } from '../components/split-view/SplitView';
import { TextEditor } from '../editor/components/TextEditor';
import { EditorToolBar } from '../editor/components/editor-tool-bar/EditorToolBar';
import { WiredOutline as Outline } from '../editor/components/outline/Outline';
import { AppMenu } from '../menu';
import { Insights } from '../insights';
import { useStore } from '../store';

export const Writer = observer((_: RouteComponentProps) => {
  const { insights } = useStore();

  return (
    <>
      <AppMenu />
      <div className={`
        c-writer__editor-container
        ${insights.visible ? 'c-writer__editor-container--insights-open' : ''}
      `}>
        <SplitView
          minSize={250}
          expandToMin={true}
        >
          <Outline />
          <div className="c-writer__editor">
            <div className="c-writer__toolbar">
              <EditorToolBar />
            </div>
            <div className="c-writer__text-editor">
              <TextEditor />
            </div>
          </div>
        </SplitView>
      </div>
      <div className="c-writer__metrics-container">
        {insights.visible && <Insights />}
      </div>
    </>
  );
});
