/**
 * Tells if onboarding is being surpressed. This will really only by used by
 * automated tests.
 *
 * @returns true if onboarding should be skipped no matter what, false if
 * onboarding can be shown if necessary.
 */
export function skipOnboarding() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return urlSearchParams.has('skipOnboarding');
}
