import CodeMirror, { Token, Editor, Range } from 'codemirror';
import { chain, focus } from '../command-helpers';
import { wrapRange } from './wrap-range';
import {
  LETTERING_CONTENT,
  PARAGRAPH,
  PARAGRAPH_BOLD,
  LETTERING_LINE,
  LETTERING_BOLD,
} from '../../mode/token';

const STAR_STAR = '**';

/**
 * CodeMirror command that inserts bold stars.
 *
 * @param cm CodeMirror Editor
 */
export const boldCommand = chain([
  focus,
  runBold,
]);

function runBold(cm: CodeMirror.Editor) {
  const selection = cm.listSelections()[0];

  if (!selectionCanBeBolded(cm, selection)) {
    return;
  }

  if (selection.empty()) {
    toggleBoldAtCursor(cm);
  } else {
    const selectedTokens = tokensInRange(
      cm,
      selection.from().line,
      selection.from().ch,
      selection.to().ch,
    );

    if (selectedTokens.length === 1 && isBold(selectedTokens[0].type)) {
      unbold(cm, selectedTokens[0], selection.from().line, selection.from().ch, selection.to().ch);
    } else if (selectedTokens.length >= 1 && selectedTokens.every(t => !isBold(t.type))) {
      // wrap selection with bold
      const lineNumber = cm.getCursor().line;
      const originalContent = cm.getLine(lineNumber);
      const newContent = wrapRange(
        originalContent,
        selection.from().ch,
        selection.to().ch,
        STAR_STAR,
        STAR_STAR
      );

      cm.operation(() => {
        cm.replaceRange(newContent, {
          ch: 0,
          line: lineNumber
        }, {
          ch: originalContent.length,
          line: lineNumber
        });

        cm.setSelection({
          ch: selection.from().ch + STAR_STAR.length,
          line: lineNumber
        }, {
          ch: selection.to().ch + STAR_STAR.length,
          line: lineNumber
        });
      });
    }
  }
}

function toggleBoldAtCursor(cm: CodeMirror.Editor) {
  const cursor = cm.getCursor();
  const token = cm.getTokenAt(cursor);

  if (isBold(token.type)) {
    unbold(cm, token, cursor.line, cursor.ch, cursor.ch);
  } else {
    const [wordRangeStart, wordRangeEnd] = findWordRangeAtCursor(cursor, token);
    const cursorWord = token.string.slice(wordRangeStart, wordRangeEnd);

    // cursor is touching a non-bold word, bold *just* that word
    if (cursorWord.length > 0) {
      // bold the word
      const newToken = wrapRange(
        token.string,
        wordRangeStart,
        wordRangeEnd,
        STAR_STAR,
        STAR_STAR
      );

      cm.replaceRange(newToken, {
        line: cursor.line,
        ch: token.start
      }, {
        line: cursor.line,
        ch: token.end
      });

      cm.setSelection({
        ch: cursor.ch + STAR_STAR.length,
        line: cursor.line
      });

    } else { // cursor is not touching a word
      // insert 4 stars at cursor
      const originalLineContent = cm.getLine(cursor.line);
      const newLineContent = wrapRange(
        originalLineContent,
        cursor.ch,
        cursor.ch,
        STAR_STAR,
        STAR_STAR
      );

      cm.operation(() => {
        cm.replaceRange(newLineContent, {
          ch: 0,
          line: cursor.line
        }, {
          ch: originalLineContent.length,
          line: cursor.line
        });

        cm.setSelection({
          ch: cursor.ch + STAR_STAR.length,
          line: cursor.line
        });
      });
    }
  }
}

/**
 * Walk out from the cursor in each direction until non word characters are
 * found, then return the character range.
 *
 * @param cursor
 * @param token
 */
function findWordRangeAtCursor(cursor: CodeMirror.Position, token: CodeMirror.Token): [number, number] {
  const tokenRelativeCursorCh = cursor.ch - token.start;

  let wordRangeStart = tokenRelativeCursorCh;
  while (isWordCharacter(token.string[wordRangeStart - 1])) {
    wordRangeStart -= 1;
  }

  let wordRangeEnd = tokenRelativeCursorCh;
  while (isWordCharacter(token.string[wordRangeEnd])) {
    wordRangeEnd += 1;
  }

  return [wordRangeStart, wordRangeEnd];
}

/**
 * Unbold a token and adjust the selection, if necessary.
 *
 * @param cm
 * @param token
 * @param line
 * @param selectionFromCh
 * @param selectionToCh
 */
function unbold(cm: Editor, token: Token, line: number, selectionFromCh: number, selectionToCh: number) {
  const withoutStars = token.string.slice(STAR_STAR.length, -STAR_STAR.length);

  cm.replaceRange(withoutStars, {
    line: line,
    ch: token.start
  }, {
    line: line,
    ch: token.end
  });

  cm.setSelection({
    ch: selectionFromCh + deltaAfterStarRemoval(selectionFromCh, token),
    line: line
  }, {
    ch: selectionToCh + deltaAfterStarRemoval(selectionToCh, token),
    line: line
  });
}

/**
 * Figure out how far a position (i.e. the cursor) needs to move after its bold
 * token is unbolded. `cursor.ch + delta` will relocate the cursor to a spot
 * that makes sense.
 *
 * ## Logic overview
 *
 * If position is in the stars, move it to the start or end of string.
 *
 *     "*|*some bold**" => "|some bold"
 *     "**some bold*|*" => "some bold|"
 *
 * If position is not in the stars, maintain relative location in the string.
 *
 *     "**som|e bold**" => "som|e bold"
 *
 * @param position Position to move. Usually the cursor's `ch` but this can also
 * be the start/end of a selection.
 * @param boldToken Bold token that 1) contains position, 2) is being unbolded
 */
function deltaAfterStarRemoval(position: number, boldToken: Token): number {
  if (!isBold(boldToken.type)) {
    throw new Error(`Expected bold token but received "${boldToken.string}"`);
  }

  const isInFrontStars = position - boldToken.start <= STAR_STAR.length;
  const isInBackStars = boldToken.end - position <= STAR_STAR.length;

  if (isInFrontStars) {
    // Position needs to move left by the number of token's front stars that are
    // before the position, if any.
    return -(position - boldToken.start);
  } else if (isInBackStars) {
    // Position needs to move left by 2 spaces (for the front stars) plus one
    // space for every back star that is before the position, if any
    return -(STAR_STAR.length + (STAR_STAR.length - (boldToken.end - position)));
  } else {
    // Position is somewhere in the regular text part of the token. Move it left
    // to account for front stars going away.
    return -STAR_STAR.length;
  }
}

function isWordCharacter(ch: string | null): boolean {
  return ch != null && /^\w$/.test(ch);
}

function selectionCanBeBolded(cm: Editor, selectedRange: Range): boolean {
  // Can't bold when there's a multi line selection
  if (selectedRange.from().line !== selectedRange.to().line) {
    return false;
  }

  const lineNumber = selectedRange.from().line;
  const lineTokens = cm.getLineTokens(lineNumber);

  // just a cursor, no selection
  if (selectedRange.empty()) {
    const line = cm.getLine(lineNumber);

    // can always bold on a blank line
    if (line === '') {
      return true;
    }

    // handle when cursor is in lettering content area but there's no content
    if (isLetteringLine(lineTokens)) {
      const colonIndex = line.indexOf(':');
      if (selectedRange.from().ch === line.length && colonIndex !== -1) {
        return true;
      }
    }

    const tokenType = cm.getTokenTypeAt(selectedRange.from());
    const nextTokenType = cm.getTokenTypeAt({
      ...selectedRange.from(),
      ch: selectedRange.from().ch + 1
    });

    return tokenType == null
      ? isBoldable(nextTokenType)
      : isBoldable(tokenType);
  } else {
    return lineTokens
      // filter down to selected tokens
      .filter(token => token.end > selectedRange.from().ch && token.start < selectedRange.to().ch)
      .every(token => isBoldable(token.type));
  }
}

function tokensInRange(cm: Editor, line: number, fromCh: number, toCh: number = fromCh): Array<Token> {
  return cm.getLineTokens(line)
    .filter(token => token.end > fromCh && token.start < toCh);
}

function isBoldable(tokenType: string | null): boolean {
  if (tokenType == null) return false;

  return tokenType.includes(LETTERING_CONTENT)
    || tokenType.includes(PARAGRAPH);
}

function isLetteringLine(tokens: Array<Token>): boolean {
  return tokens
    .some(token => (token.type || '')
    .includes(LETTERING_LINE));
}

function isBold(tokenType: string | null): boolean {
  if (tokenType == null) return false;

  return tokenType.includes(LETTERING_BOLD)
    || tokenType.includes(PARAGRAPH_BOLD);
}
