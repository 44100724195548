import { Editor, KeyMap, Pass } from 'codemirror';
import { TabStop } from './tab-stops';

/**
 * css class applied to the line when lettering snippet is active.
 *
 * Keep this class name in sync with theme.css
 */
const ACTIVE_LETTERING_SNIPPET = 'active-lettering-snippet';

export interface LetteringSnippetConfig {
  /** The states of the lettering snippet state machine. */
  tabStops: Array<TabStop>;
  /** Text to insert when the snippet is triggered. */
  template: string;
  /** Origin string used for helping CodeMirror keep track of undo/redo sets. */
  origin: string;
}

export function trigger(cm: Editor, config: LetteringSnippetConfig) {
  const tabStops = addSentinels(config.tabStops);

  const lineNumber = cm.getCursor().line;

  let tabStopIndex = 0;
  const keyMap = makeKeyMap(goToNextStop, goToPreviousStop, exitSnippet);

  enterSnippet();
  goToNextStop();

  function enterSnippet() {
    cm.addKeyMap(keyMap);
    cm.on('cursorActivity', handleCursorActivity);
    cm.addLineClass(lineNumber, 'text', ACTIVE_LETTERING_SNIPPET);

    // insert template to get things started
    cm.replaceRange(config.template, cm.getCursor(), undefined, config.origin);
  }

  function exitSnippet() {
    cm.removeLineClass(lineNumber, 'text', ACTIVE_LETTERING_SNIPPET);
    cm.off('cursorActivity', handleCursorActivity);
    cm.removeKeyMap(keyMap);
  }

  function goToPreviousStop() {
    move(-1);
  }

  function goToNextStop() {
    move(1);
  }

  function move(delta: number) {
    // turn off previous stop, if possible
    const previousStop = tabStops[tabStopIndex];
    previousStop?.deactivate && previousStop.deactivate(cm);

    // figure out next stop
    tabStopIndex += delta;
    const nextStop = tabStops[tabStopIndex];

    // turn on next stop, if any
    if (nextStop) {
      nextStop.activate(cm);
    } else {
      exitSnippet();
    }
  }

  // Exit if it seems like user is trying to get out of snippet
  function handleCursorActivity(cm: Editor) {
    // moved to different line
    if (lineNumber !== cm.getCursor().line) {
      exitSnippet();
    }

    // moved to start of line
    if (cm.getCursor().ch === 0) {
      exitSnippet();
    }
  }
}

function addSentinels(tabStops: Array<TabStop>): Array<TabStop> {
  return [
    {
      name: 'left-sentinel',
      activate(cm) {
        cm.execCommand('goLineLeft');
      }
    },
    ...tabStops,
    {
      name: 'right-sentinel',
      activate(cm) {
        cm.operation(() => {
          cm.execCommand('goLineRight');
          cm.execCommand('newlineAndIndent');
        });
      }
    }
  ];
}

function makeKeyMap(next: () => void, prev: () => void, exit: () => void): KeyMap {
  return {
    Tab() {
      next();
    },
    'Shift-Tab'() {
      prev();
    },
    Enter() {
      exit();
      return Pass;
    },
    Esc() {
      exit();
      return Pass;
    }
  };
}
