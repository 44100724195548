import { getString, setString } from '../localstorage';

const KEY = 'current-script-source';

/**
 * Cache script source.
 */
export const set = (script: string) => setString(KEY, script);

/**
 * Get script source from cache.
 */
export const get = () => getString(KEY, () => '');
