import React, { Component, createRef } from 'react';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import CodeMirror, {
  CommandActions,
  commands as codeMirrorCommands
} from 'codemirror';

import 'codemirror/addon/display/placeholder';
import 'codemirror/addon/scroll/scrollpastend';
import 'codemirror/addon/scroll/simplescrollbars';

import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/scroll/simplescrollbars.css'
import './CodeMirror.css';
import './theme.css';

import { EditorChangeEvent, EditorScrollEvent, EditorCommand, EditorCommandName } from '../../types';
import * as perf from '../../../perf';

import { createPreprocessor } from './preprocessor';
import { capitalizeLetteringMetadata } from './preprocessor/capitalize-lettering-metadata';
import { MODE, THEME } from './mode';
import { boldCommand } from './commands/bold/bold-command';
import { NO_TARGET_LINE } from '../../constants';
import { insertPage } from './commands/insert-page';
import { insertPanel } from './commands/insert-panel';
import { caption } from './commands/lettering/caption';
import { sfx } from './commands/lettering/sfx';
import { create as createDialogueCommand } from './commands/lettering/dialogue';
import { formatForCodeMirror } from '../../../util/keyboard';
import { domReady } from '../../../util/dom-ready';
import { CodeMirrorContext, CodeMirrorApi } from './context/code-mirror-context';
import { EMPTY_GUTTER } from './gutters/gutter-ids';

/**
 * The line this many pixels below viewport top is considered the "current" line
 */
const CURRENT_LINE_SCROLL_OFFSET = 90;

interface Props {
  /**
   * When this changes, the editor's value is changed to this value.
   */
  initialValue: string;
  /**
   * When this changes, editor scrolls so targetLine becomes the current line.
   */
  targetLine: number;
  /** When this changes, editor performs the command. */
  command: EditorCommand | null;
  characters: Array<string>;
  onChange: (event: EditorChangeEvent) => void;
  onScroll: (event: EditorScrollEvent) => void;
}

interface State {
  isScrolled: boolean;
  api?: CodeMirrorApi;
}

export default class CodeMirrorComponent extends Component<Props, State> {
  rootRef: React.RefObject<HTMLDivElement>;
  cm: CodeMirror.Editor | null;
  preprocessLines: ReturnType<typeof createPreprocessor>;

  /** Current line the cursor is on. */
  cursorLine: number;
  /**
   * If the cursor changed to a different line and this is true, need to
   * preprocess the editor content.
   */
  shouldPreprocessNextLineChange: boolean;

  constructor(props: Props) {
    super(props);

    this.state = {
      isScrolled: false,
    };

    this.cm = null;
    this.rootRef = createRef<HTMLDivElement>();
    this.preprocessLines = createPreprocessor();

    this.cursorLine = 0;
    this.shouldPreprocessNextLineChange = false;
  }

  render() {
    return (
      <div
        className={`
          c-codemirror
          ${this.state.isScrolled ? 'c-codemirror--is-scrolled' : ''}
        `}
        ref={this.rootRef}
      >
        <CodeMirrorContext.Provider value={this.state.api ?? null}>
          {this.props.children}
        </CodeMirrorContext.Provider>
      </div>
    );
  }

  componentDidUpdate(prevProps: Props) {
    const cm = this.getCodeMirrorInstance();

    if (this.props.command != null && this.props.command !== prevProps.command) {
      this.executeCommand(this.props.command);
    }

    if (this.props.targetLine !== prevProps.targetLine && this.props.targetLine !== NO_TARGET_LINE) {
      // put cursor on target line
      cm.focus();
      cm.setCursor({
        line: this.props.targetLine,
        // end of line
        ch: cm.getLine(this.props.targetLine).length + 1
      });

      // scroll so target line is near the top
      const coords = cm.charCoords({ line: this.props.targetLine, ch: 0 }, 'local');
      cm.scrollTo(null, coords.bottom - CURRENT_LINE_SCROLL_OFFSET);
    }
  }

  executeCommand(command: EditorCommand): void {
    const cmCommandsByCwCommand: Record<EditorCommandName, string> = {
      'insertPage' : 'cw:insertPage',
      'insertPanel': 'cw:insertPanel',
      'dialogue'   : 'cw:insertDialogue',
      'caption'    : 'cw:insertCaption',
      'sfx'        : 'cw:insertSfx',
      'bold'       : 'cw:bold',
    };

    const codemirrorCommand = cmCommandsByCwCommand[command.name];

    if (codemirrorCommand) {
      this.getCodeMirrorInstance().execCommand(codemirrorCommand);
    } else {
      throw new Error(`No CodeMirror command mapped for ${command.name}`);
    }
  }

  getCodeMirrorInstance(): CodeMirror.Editor {
    if (this.cm == null) {
      throw new Error('cm is not set yet');
    }

    return this.cm;
  }

  getRootElement(): HTMLElement {
    if (this.rootRef.current == null) {
      throw new Error('root ref is not available yet');
    }

    return this.rootRef.current;
  }

  /**
   * Internal callback for when the cursor moves to a different line.
   */
  private onCursorChangedLine(oldCursorLine: number, cursor: CodeMirror.Position): void {
    if (this.shouldPreprocessNextLineChange) {
      const oldLines = this.getCodeMirrorInstance().getValue().split('\n');
      const newLines = this.preprocessLines({
        lines: oldLines,
        // Hack: tell preprocessor that cursor is actually 1 line above the
        // highest relevant line. This ensures that all possibly affected
        // lines will be preprocessed.
        cursorLine: Math.min(oldCursorLine, cursor.line) - 1,
        fromLine: oldCursorLine,
        toLine: oldCursorLine
      });

      this.replaceLines(newLines, oldLines, cursor);
      this.emitChange(newLines);
    }

    this.shouldPreprocessNextLineChange = false;
  }

  componentDidMount() {
    const commands = codeMirrorCommands as Readonly<CommandActions> & {
      [key: string]: (editor: CodeMirror.Editor) => void;
    };

    // map names to our custom commands
    commands['cw:bold'] = boldCommand;
    commands['cw:insertPage'] = insertPage;
    commands['cw:insertPanel'] = insertPanel;
    commands['cw:insertCaption'] = caption;
    commands['cw:insertSfx'] = sfx;
    commands['cw:insertDialogue'] = createDialogueCommand(() => this.props.characters);

    this.cm = CodeMirror(this.getRootElement(), {
      mode: MODE,
      theme: THEME,
      value: this.props.initialValue,
      inputStyle: 'contenteditable',
      placeholder: 'Adventure starts here...',
      lineWrapping: true,
      scrollPastEnd: true,
      scrollbarStyle: 'simple',
      tabSize: 8,
      gutters: [EMPTY_GUTTER],
      extraKeys: {
        [formatForCodeMirror(['Meta', 'B'])]: 'cw:bold',
        [formatForCodeMirror(['Alt', '1'])]: 'cw:insertPage',
        [formatForCodeMirror(['Alt', '2'])]: 'cw:insertPanel',
        [formatForCodeMirror(['Alt', '3'])]: 'cw:insertDialogue',
        [formatForCodeMirror(['Alt', '4'])]: 'cw:insertCaption',
        [formatForCodeMirror(['Alt', '5'])]: 'cw:insertSfx',
        // no-op to prevent the CodeMirror default action: reverse indent
        /* eslint-disable-next-line @typescript-eslint/no-empty-function */
        [formatForCodeMirror(['Shift', 'Tab'])]() {},
      }
    });

    this.cm.setSize('100%', '100%');

    this.cm.on('cursorActivity', cm => {
      const cursor = cm.getCursor();

      if (this.cursorLine !== cursor.line) {
        const oldLine = this.cursorLine;
        this.cursorLine = cursor.line;

        this.onCursorChangedLine(oldLine, cursor);
      }
    });

    this.cm.on('beforeChange', (_, change) => {
      // change is from an updateable paste
      if (change.origin === 'paste' && change.update) {
        // it could have lettering with lowercase metadata, all caps it
        const newText = change.text
          .map(line => capitalizeLetteringMetadata(line));

        change.update(change.from, change.to, newText);
      }
    });

    this.cm.on('change', (cm, change) => {
      // This event listener is only for handling user changes, so ignore
      // changes from the script preprocessor.
      if (change.origin === 'preprocessing') {
        return;
      }

      perf.start('change-event');

      // Grab cursor position *before* preprocessing because cursor might need
      // to be put back to its original position.
      const cursor = cm.getCursor();

      // Every time there is a change we might need to preprocess it when the
      // user is done on that line, so set this signal for onCursorChangedLine()
      // to react to.
      this.shouldPreprocessNextLineChange = true;
      // but if cursor changed line as part of this current change, don't need
      // to preprocess the change later because we're about to preprocess below.
      if (cursor.line !== this.cursorLine) {
        this.shouldPreprocessNextLineChange = false;
      }

      const oldLines = cm.getValue().split(/\n/);
      const newLines = this.preprocessLines({
        lines: oldLines,
        cursorLine: cursor.line,
        fromLine: change.from.line,
        toLine: change.to.line
      });

      // Even though this doesn't use newLines, this bailout needs to be after
      // the preprocessor has seen the oldLines because the preprocessor is
      // stateful. If it doesnt see every change, the next time it runs things
      // will be weird.
      if (change.origin === 'undo' || change.origin === 'redo') {
        this.emitUndoRedoChange(oldLines);

        perf.end('change-event');
        return;
      }

      perf.start('apply-preprocessing-changes');

      this.replaceLines(newLines, oldLines, cursor);

      perf.end('apply-preprocessing-changes');

      // Only the preprocessed script lines go to the outside world
      this.emitChange(newLines);

      perf.end('change-event');
    });

    this.cm.on('scroll', cm => {
      this.emitScroll(cm);
    });

    // start with cursor in the text editor
    this.cm.focus();

    // Hack for #48
    domReady.then(() => this.cm?.refresh());

    // trigger a render so feature components can access the editor
    this.setState({
      api: {
        editor: this.cm
      }
    });
  }

  private replaceLines(
    newLines: Array<string>,
    oldLines: Array<string>,
    cursor: CodeMirror.Position
  ): void {
    const cm = this.getCodeMirrorInstance();

    // apply changes from preprocessor, if any
    cm.operation(() => {
      let cursorLineLengthDelta = 0;

      // Apply changes from the bottom up to make things work nicer with the
      // weird way we replaceRange many times and debounce undo/redo changes.
      const length = Math.max(oldLines.length, newLines.length);
      for (let index = length - 1; index >= 0; index--) {
        const newLine = newLines[index] || '';
        const oldLine = oldLines[index] || '';

        if (newLine !== oldLine) {
          const from = { line: index, ch: 0 };
          const to = { line: index, ch: oldLine.length };

          cm.replaceRange(newLine, from, to, 'preprocessing');

          if (index === cursor.line) {
            cursorLineLengthDelta = newLine.length - oldLine.length;
          }
        }
      }

      // Line replacements may cause cursor to move so put it back
      if (!cm.somethingSelected()) {
        cm.setCursor({
          line: cursor.line,
          ch: cursor.ch + Math.max(cursorLineLengthDelta, 0)
        });
      }
    });
  }

  emitScroll = throttle((cm: CodeMirror.Editor) => {
    const scrollInfo = cm.getScrollInfo();
    const scrollTop = Number(scrollInfo.top);

    // Sets state for scroll decoration.
    const isScrolled = scrollTop >= 2;
    if (isScrolled !== this.state.isScrolled) {
      this.setState({isScrolled});
    }

    this.props.onScroll({
      currentLine: cm.lineAtHeight(scrollTop + CURRENT_LINE_SCROLL_OFFSET, 'local'),
    });
  }, 100);

  /**
   * Emit a change event that happened because of an undo or redo by the user.
   *
   * ## More detail
   *
   * When applying changes from the script preprocessor, an update from one run
   * of the preprocessor is actually an operation containing n updates to the
   * CodeMirror Editor (1 update per line changed by preprocessor).
   *
   * Because of that, a single undo will trigger n change events from CM. So
   * undo and redo change event use this special method so we don't spam the
   * store with change events.
   *
   * Since undo/redo is relatively rare, this isn't 100% vital. If this causes
   * issues later it can probably be removed without too much harm.
   */
  emitUndoRedoChange = debounce(lines => {
    this.emitChange(lines);
  }, 100);

  /**
   * Emit a change event that happened due to an edit by the user.
   */
  emitChange(lines: Array<string>): void {
    this.props.onChange({
      lines
    });
  }
}
