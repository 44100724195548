import { createStore } from './store';
import { get as getSettings, onSettingsChange } from './settings';
import { allowErrorReporting } from './error-handling';
import { getString, setString } from './localstorage';
import { notifyVersionUpdate } from './notifications';

type BootUpOptions = {
  /**
   * true to skip the onboarding process, even if it's the user's first visit.
   */
  skipOnboarding: boolean,
  /**
   * Initial script value.
   */
  script: string,
};

/**
 * Wire stuff up and get the app in a usable state.
 */
export function bootUp(options: BootUpOptions): ReturnType<typeof createStore> {
  allowErrorReporting(getSettings().reportErrors);

  onSettingsChange(settings => {
    allowErrorReporting(settings.reportErrors);
  });

  const store = createStore();

  // Set initial script value
  store.editor.replaceScriptWithInitial(options.script);

  checkVersionUpdate({
    firstVisit() {
      !options.skipOnboarding && store.modals.showOnBoarding();
    },
    versionUpdate(newVersion) {
      /*
       * Hack: Run this after a timeout to give React a chance to render (toast
       * won't show otherwise).
       */
      setTimeout(() => notifyVersionUpdate(newVersion), 1000);
    },
  });

  return store;
}

type VersionUpdateCallbacks = {
  /**
   * Called when `current version === previous version`
   *
   * @param version - Current version
   */
  noChange?: (version: string) => void,
  /**
   * Called when there was a previous version and `current version !== previous version`.
   *
   * @param newVersion - Current version
   * @param oldVersion - Previous version
   */
  versionUpdate?: (newVersion: string, oldVersion: string) => void;
  /**
   * Called when there was no previous version.
   *
   * @param version - Current version
   */
  firstVisit?: (version: string) => void;
};

function checkVersionUpdate(callbacks: VersionUpdateCallbacks) {
  const currentVersion = process.env.REACT_APP_VERSION;
  if (!currentVersion) {
    throw new Error(`process.env.REACT_APP_VERSION is not set`);
  }

  const VERSION_KEY = 'version';
  const NO_VERSION = '_';

  // Get stored version
  const previousVersion = getString(VERSION_KEY, () => NO_VERSION);

  // Update stored version, if needed
  if (currentVersion !== previousVersion) {
    setString(VERSION_KEY, currentVersion);
  }

  // Report based on differences in previous/current versions
  if (previousVersion === NO_VERSION) {
    callbacks.firstVisit?.(currentVersion);
  } else if (currentVersion !== previousVersion) {
    callbacks.versionUpdate?.(currentVersion, previousVersion);
  } else {
    callbacks.noChange?.(currentVersion);
  }
}
