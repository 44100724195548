import { saveFile } from './save-file';
import { SaveFileOutcome } from './save-file';
import {
  ScriptFile,
  SCRIPT_EXTENSION,
  DEFAULT_ROOT_FILENAME,
  SCRIPT_DESCRIPTION,
} from './script-file';

export type SaveScriptOutcome = SaveFileOutcome;

export function saveScript(script: ScriptFile, suggestedRootFilename = DEFAULT_ROOT_FILENAME): Promise<SaveScriptOutcome> {
  return saveFile(script.toBlob(), {
    fileName: `${suggestedRootFilename}.${SCRIPT_EXTENSION}`,
    extensions: [`.${SCRIPT_EXTENSION}`],
    description: SCRIPT_DESCRIPTION,
    handle: script.handle
  });
}