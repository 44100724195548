import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { EditorChangeEvent, EditorScrollEvent } from '../types';
import { useStore } from '../../store/use-store';
import CodeMirror from './codemirror/CodeMirror';
import { WordCounts } from './codemirror/gutters/WordCounts';
import { PanelCounts } from './codemirror/panel-counts/PanelCounts';
import { BrowserSpellcheck } from './codemirror/spellcheck/BrowserSpellcheck';

export const TextEditor = observer(() => {
  const {
    script: scriptStore,
    editor: editorStore,
    settings: settingsStore,
  } = useStore();

  const onChange = useCallback((event: EditorChangeEvent) => {
    editorStore.updateScript(event.lines);
  }, [editorStore]);

  const onScroll = useCallback((event: EditorScrollEvent) => {
    editorStore.updateScroll(event);
  }, [editorStore]);

  return (
    <CodeMirror
      key={editorStore.key}
      initialValue={editorStore.initialValue}
      targetLine={editorStore.targetLine}
      command={editorStore.command}
      characters={scriptStore.speakers}
      onChange={onChange}
      onScroll={onScroll}
    >
      <WordCounts />
      <PanelCounts />
      {settingsStore.settings.browserSpellcheck && <BrowserSpellcheck />}
    </CodeMirror>
  );
});
