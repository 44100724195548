import wordcount from 'wordcount';

export type TextChunk = { content: string };

export default function countWords(chunks: Array<TextChunk>) {
  const text = chunks
    .map(chunk => chunk.content)
    .join('')
    // words with apostrophes count as one word
    .replace(/'/g, '');

  return wordcount(text);
}
