import React, { useEffect } from 'react';

type ClickOutsideHandler = (event: MouseEvent | TouchEvent | FocusEvent) => void;

// https://usehooks.com/useOnClickOutside/
export function useOnClickOutside<T extends HTMLElement>(ref: React.RefObject<T>, handler: ClickOutsideHandler) {
  useEffect(
    () => {
      const listener = (event: MouseEvent | TouchEvent | FocusEvent) => {
        // Casting to Node due to https://stackoverflow.com/a/61164277/1865262
        if (!ref.current || ref.current.contains(event.target as Node)) {
          // Do nothing if clicking ref's element or descendent elements
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      document.addEventListener('blur', listener)

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
        document.removeEventListener('blur', listener)
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}
