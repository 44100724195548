import { ensureCursorOnBlankLine, chain, focus } from '../command-helpers';
import { trigger } from './lettering-snippet';
import { createStaticSubjectTabStops } from './tab-stops';

const ORIGIN = '+insertCaption';
const SUBJECT = 'CAPTION';

export const caption = chain([
  focus,
  cm => ensureCursorOnBlankLine(cm, ORIGIN),
  cm => trigger(cm, {
    template: `\t${SUBJECT} (): content`,
    origin: ORIGIN,
    tabStops: createStaticSubjectTabStops(SUBJECT, ORIGIN)
  }),
]);
