import React, { MouseEventHandler } from 'react';
import { WithReactChildren } from '../../components/types';
import './ToolTip.css';

type ToolTipProps = {
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
} & WithReactChildren;

// NOTE: We might refactor this out of the toolbar eventually.
const ToolTip = (props: ToolTipProps) =>
  <div className={`c-tooltip`} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
    {React.Children.toArray(props.children).filter((_, i) => i > 0)}
    <div key="popover" className="c-tooltip__position-helper">
      <div className="c-tooltip__pop-over">
        {React.Children.toArray(props.children)[0]}
      </div>
    </div>
  </div>;

export default ToolTip;
