import {
  observable,
  action,
  makeObservable,
} from 'mobx';

import { MessageBoxProps } from './components/message-box/MessageBox';
import { OnBoardingProps } from './components/onboarding/OnBoarding';
import { SettingsProps } from './components/settings/Settings';
import { AboutProps } from './components/about/About';

type MesasgeBoxOptions = Omit<MessageBoxProps, 'onResult'>;
type OnBoardingState = Pick<OnBoardingProps, 'onClose'>;

export class ModalState {
  messageBox?: MessageBoxProps;
  onBoarding?: OnBoardingState;
  settings?: SettingsProps;
  about?: AboutProps;

  constructor() {
    makeObservable(this, {
      // observables
      messageBox: observable.ref,
      onBoarding: observable.ref,
      settings: observable.ref,
      about: observable.ref,

      // actions
      showMessageBox: action,
      showOnBoarding: action,
      showSettings: action,
      showAbout: action,
      hide: action,
    });
  }

  showMessageBox(options: MesasgeBoxOptions) {
    return new Promise<number>(resolve => {
      this.messageBox = {
        ...options,
        onResult: (index: number) => {
          this.hide();
          resolve(index)
        }
      };
    });
  }

  showOnBoarding() {
    return new Promise<void>(resolve => {
      this.onBoarding = {
        onClose: () => {
          this.hide();
          resolve();
        }
      };
    });
  }

  showSettings() {
    return new Promise<void>(resolve => {
      this.settings = {
        onClose: () => {
          this.hide();
          resolve();
        }
      };
    });
  }

  showAbout() {
    return new Promise<void>(resolve => {
      this.about = {
        onClose: () => {
          this.hide();
          resolve();
        }
      };
    })
  }

  /**
   * Hide the visible modal.
   */
  hide() {
    this.messageBox = undefined;
    this.onBoarding = undefined;
    this.settings = undefined;
    this.about = undefined;
  }
}
