import { ensureCursorAtLineStart, chain, focus } from './command-helpers';

/**
 * Insert a page.
 *
 * @param cm
 */
export const insertPanel = chain([
  focus,
  cm => ensureCursorAtLineStart(cm, '+insertPanel'),
  cm => cm.replaceRange('panel\n', cm.getCursor(), undefined, '+insertPanel'),
]);
