import {
  action,
  computed,
  makeObservable,
  observable,
  IObservableValue,
} from 'mobx';
import { debouncedObservable } from '../util/debounced-observable';
import { Spread } from '../parser/nodes';

import { ScriptStore } from '../script';
import { SpreadStat } from './types';

export class InsightsStore {
  visible: boolean;

  private locatedSpreads: IObservableValue<Array<Spread>>;

  constructor(scriptStore: ScriptStore) {
    makeObservable(this, {
      visible: observable,

      panelCountsBySpread: computed,
      spokenWordCountsBySpread: computed,

      toggle: action,
    });

    this.visible = false;
    this.locatedSpreads = debouncedObservable([], () => scriptStore.locatedSpreads, 1000).observable;
  }

  get panelCountsBySpread(): Array<SpreadStat<number>> {
    return this.locatedSpreads.get()
      .map((spread, index) => {
        return {
          pages: spread.pagesSpanned,
          value: spread.panelCount,
          index,
          lineNumber: spread.lineNumber,
        };
      });
  }

  get spokenWordCountsBySpread(): Array<SpreadStat<number>> {
    return this.locatedSpreads.get()
      .map((spread, index) => {
        return {
          pages: spread.pagesSpanned,
          value: spread.wordCount,
          index,
          lineNumber: spread.lineNumber,
        };
      });
  }

  toggle() {
    this.visible = !this.visible;
  }
}
