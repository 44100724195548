export class Sequence {
  private number: number;

  constructor(start: number) {
    this.number = start;
  }

  next(): number {
    return this.number++;
  }
}
