import { mac } from './browser';
import isHotkey from 'is-hotkey';

/**
 * Cmd on macs and Ctrl everywhere else.
 */
export const META = 'Meta';

/**
 * Convert generic key combo into a platform-specific version that CM can use.
 *
 * Example:
 *   [Meta, 1] => Cmd-1 or Ctrl-1
 *
 * @param keyCombo
 */
export function formatForCodeMirror(keyCombo: Array<string>): string {
  // Get the combo in a form that CM likes
  return keyCombo
    // CM doesn't handle Meta/Mod so translate it manually
    .map(key => key === META
      ? mac ? 'Cmd' : 'Ctrl'
      : key
    )
    // CM looks for dashes as separators
    .join('-');
}

/**
 * Convert key combo into keys we can show to a user.
 */
export function forDisplay(keyCombo: Array<string>): Array<string> {
  return keyCombo
    // Replace Meta with Ctrl or the squeeedelyboo-boi '⌘'
    .map(key => key === META
      ? mac ? '⌘' : 'Ctrl'
      : key
    );
}

export function hasModifier(event: KeyboardEvent): boolean {
  return event.ctrlKey || event.metaKey || event.shiftKey || event.altKey;
}

/**
 * Create a function that tells if a key event is a specific key combo.
 *
 * @param keyCombo Key combo we're looking for
 */
export function isKeyCombo(keyCombo: Array<string>): (event: KeyboardEvent) => boolean {
  return isHotkey(formatForIsHotkey(keyCombo));
}

// Get the combo in a form that the hotkey module (is-hotkey) likes.
function formatForIsHotkey(keyCombo: Array<string>): string {
  return keyCombo
    // Mod is how is-hotkey refers to Cmd/Ctrl
    .map(key => key === META ? 'Mod' : key)
    // is-hotkey wants plus signs between keys
    .join('+');
}

export function isEscape(event: KeyboardEvent): boolean {
  return event.key === 'Esc' || event.key === 'Escape'
}