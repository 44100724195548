import { ensureCursorOnBlankLine, chain, focus } from '../command-helpers';
import { trigger } from './lettering-snippet';
import { createDialogueTabStops } from './tab-stops';

const DEFAULT_SUBJECT = 'CHARACTER';
const ORIGIN = '+insertDialogue';

export function create(getCharacterNames: () => Array<string>) {
  return chain([
    focus,
    cm => ensureCursorOnBlankLine(cm, ORIGIN),
    cm => trigger(cm, {
      template: `\t${DEFAULT_SUBJECT} (): content`,
      origin: ORIGIN,
      tabStops: createDialogueTabStops(getCharacterNames, DEFAULT_SUBJECT)
    }),
  ]);
}
