import { LineClassification } from './types';

const PAGE_EXPANSION_PATTERN = /^page *$/i;
const PAGES_EXPANSION_PATTERN = /^pages *$/i;
const PANEL_EXPANSION_PATTERN = /^panel *$/i;

const SINGLE_PANEL_PATTERN = /^panel +\d{1,}$/i;

const SINGLE_PAGE_PATTERN = /^pages? +\d{1,}$/i;
const PAGE_RANGE_PATTERN = /^pages? +(\d{1,})-(\d{1,})$/i;
const PARTIAL_PAGE_RANGE_PATTERN = /^pages? \d{1,}-$/i;

// If line doesn't start with one of these, it's a "regular" line. This only
// works because we know what the regexes look like (above).
const classifiablePrefixes = ['p', 'P'];

// These classification types are stateless so we can use one instance of each
const PANEL_LINE: LineClassification = { type: 'panel', frozen: false };
const SINGLE_PAGE_LINE: LineClassification = { type: 'single-page', frozen: false };
const REGULAR_LINE: LineClassification = { type: 'regular', frozen: false };
const PARTIAL_PAGE_RANGE: LineClassification = { type: 'partial-page-range', frozen: false };
const INVALID_PAGE_RANGE: LineClassification = { type: 'invalid-page-range', frozen: false };

/**
 * Create a line classifier for purposes of pre-processing the script.
 *
 * @param cursorLine Zero based line number that the cursor is on. This affects
 *  how the classifier will treat certain lines.
 * @param lineCount How many script lines were changed in the last edit
 * @param lineOffset When the classifier receives line number values, this is
 *  far they are from their true line position.
 */
export default function createClassifier(cursorLine: number, lineCount: number, lineOffset: number) {
  return function classify(line: string, lineNumber: number): LineClassification {
    // Early bailout for obvious regular lines to save us from ping ponging
    // through all the regexes below, only to find out it's a regular line.
    if (isDefinitelyRegularLine(line)) {
      return REGULAR_LINE;
    }

    const cursorOnThisLine = lineNumber + lineOffset === cursorLine;
    const editingSingleLineChange = cursorOnThisLine && lineCount === 1;

    /**
     * Order matters in here for 2 reasons:
     *
     *   - Need to check against more specific patterns first because a more
     *     general pattern could take all the matches.
     *   - Matching more likely patterns first means higher chances of matching
     *     sooner which is good for performance.
     */

    if (SINGLE_PANEL_PATTERN.test(line)) {
      return editingSingleLineChange ? freeze(PANEL_LINE) : PANEL_LINE;
    }

    if (PANEL_EXPANSION_PATTERN.test(line)) {
      return editingSingleLineChange ? freeze(PANEL_LINE) : PANEL_LINE;
    }

    if (SINGLE_PAGE_PATTERN.test(line)) {
      return editingSingleLineChange ? freeze(SINGLE_PAGE_LINE) : SINGLE_PAGE_LINE;
    }

    if (PAGE_EXPANSION_PATTERN.test(line)) {
      return editingSingleLineChange ? freeze(SINGLE_PAGE_LINE) : SINGLE_PAGE_LINE;
    }

    if (PAGES_EXPANSION_PATTERN.test(line)) {
      return editingSingleLineChange ? freeze(multiPageLine(2)) : multiPageLine(2);
    }

    const pageRange = PAGE_RANGE_PATTERN.exec(line);
    if (pageRange) {
      const start = parseInt(pageRange[1], 10);
      const end = parseInt(pageRange[2], 10);

      if (isValidPageRange(start, end)) {
        return editingSingleLineChange
          ? freeze(multiPageLine(1 + end - start))
          : multiPageLine(1 + end - start);
      }

      // invalid but user is still editing the line
      if (editingSingleLineChange) {
        return freeze(INVALID_PAGE_RANGE);
      }

      // invalid and cursor is gone, change the line to something usable
      return isInvertedPageRange(start, end)
        ? multiPageLine(2)
        : SINGLE_PAGE_LINE;
    }

    if (PARTIAL_PAGE_RANGE_PATTERN.test(line)) {
      return editingSingleLineChange
        ? freeze(PARTIAL_PAGE_RANGE)
        : SINGLE_PAGE_LINE;
    }

    return REGULAR_LINE;
  };
}

function isDefinitelyRegularLine(line: string): boolean {
  return !classifiablePrefixes.includes(line[0]);
}

function isValidPageRange(start: number, end: number): boolean {
  return start < end;
}

function isInvertedPageRange(start: number, end: number): boolean {
  return start > end;
}

function multiPageLine(count: number): LineClassification {
  return {
    type: 'multi-page',
    count,
    frozen: false
  };
}

/**
 * Create a frozen copy of the given classification.
 */
function freeze(classification: LineClassification): LineClassification {
  return {
    ...classification,
    frozen: true
  };
}
