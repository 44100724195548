import React from 'react';
import Modal from '../../../components/modal/Modal';
import Button from '../../../components/button/Button';
import Switch from '../../../components/switch/Switch';
import ToolBar from '../../../components/tool-bar/ToolBar';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../store/use-store';
import { Link } from '../../../components/link/Link';
import { privacyUrl } from '../../../util/static-site-urls';
import { Select, SelectOption } from '../../../components/select/Select';
import { BoldExportStyle } from '../../../settings/types';

import './Settings.css';

export interface SettingsProps {
  /** Invoked when the modal wants to close. */
  onClose: () => void;
}

/**
 * App settings modal.
 */
export const Settings = observer((props: SettingsProps) => {
  const { settings: settingsStore } = useStore();

  const boldStyleOptions: Array<SelectOption<BoldExportStyle>> = [
    {
      label: 'Bold',
      value: 'bold'
    },
    {
      label: 'Underlined',
      value: 'underline'
    },
    {
      label: 'Bold & Underlined',
      value: 'bold-and-underline'
    }
  ];

  return (
    <Modal
      title="Settings"
      closeButtonOnClick={props.onClose}
    >
      <form onSubmit={() => props.onClose()}>
        <div className="c-settings__field">
          <Select
            id="bold-lettering-export-style"
            name="boldLetteringExportStyle"
            label="Bold lettering export style"
            describedById="bold-lettering-export-style-description"
            initialValue={settingsStore.settings.boldLetteringExportStyle}
            options={boldStyleOptions}
            onChange={value => settingsStore.update({
              boldLetteringExportStyle: value
            })}
          />
          <FieldDescription id="bold-lettering-export-style-description">
            How bold text in lettering will look in the exported script.
          </FieldDescription>
        </div>

        <div className="c-settings__field">
          <Switch
            on={settingsStore.settings.browserSpellcheck}
            onChange={on => settingsStore.update({
              browserSpellcheck: on
            })}
            id="browser-spellcheck"
            describedById="browser-spellcheck-description"
          >
            Browser spellcheck
          </Switch>
          <FieldDescription id="browser-spellcheck-description">
            Use the browser's built-in spell checker.
          </FieldDescription>
        </div>

        <div className="c-settings__field">
          <Switch
            on={settingsStore.settings.reportErrors}
            onChange={on => settingsStore.update({
              reportErrors: on
            })}
            id="error-reporting"
            describedById="error-reporting-description"
          >
            Error reporting
          </Switch>
          <FieldDescription id="error-reporting-description">
            When an application error happens, send a report to a 3rd party error monitoring service. This is optional but it helps improve ComicWriter. Read more in our <Link url={privacyUrl} newTab>privacy policy</Link>.
          </FieldDescription>
        </div>

        <ToolBar>
          <React.Fragment />
          <Button type="submit" primary>
            Close
          </Button>
        </ToolBar>
      </form>
    </Modal>
  );
});

function FieldDescription(props: {id: string, children: React.ReactNode}) {
  return (
    <p
      id={props.id}
      className="c-settings__field-description u-font-size--saya"
    >
      {props.children}
    </p>
  );
}