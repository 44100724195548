import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { bootUp } from './boot-up';
import { skipOnboarding } from './util/query-string';
import { get as getCachedScript } from './script/script-cache';

const store = bootUp({
  skipOnboarding: skipOnboarding(),
  script: getCachedScript(),
});

ReactDOM.render(
  <React.StrictMode>
    <App store={store} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
