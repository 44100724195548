export const REGULAR_LINE = 'regular';
export const SINGLE_PAGE_LINE = 'single-page';
export const MULTI_PAGE_LINE = 'multi-page';
export const PARTIAL_PAGE_RANGE_LINE = 'partial-page-range';
export const INVALID_PAGE_RANGE_LINE = 'invalid-page-range';
export const PANEL_LINE = 'panel';

interface Freezable {
  /**
   * When true, the line with this classification should not be auto-numbered,
   * it should be returned as-is.
   */
  frozen: boolean;
}

type Regular = {
  type: typeof REGULAR_LINE;
} & Freezable;

type SinglePage = {
  type: typeof SINGLE_PAGE_LINE;
} & Freezable;

type MultiPage = {
  type: typeof MULTI_PAGE_LINE;
  count: number;
} & Freezable;

type PartialPageRange = {
  type: typeof PARTIAL_PAGE_RANGE_LINE;
} & Freezable;

type InvalidPageRange = {
  type: typeof INVALID_PAGE_RANGE_LINE;
} & Freezable;

type Panel = {
  type: typeof PANEL_LINE;
} & Freezable;

export type LineClassification = Regular
  | SinglePage
  | MultiPage
  | PartialPageRange
  | InvalidPageRange
  | Panel;
