import debounce from 'lodash/debounce';
import {
  action,
  observable,
  reaction,
} from 'mobx';

/**
 * Create a mobx observable value that updates when its dependency changes, but
 * behind a debounce delay.
 *
 * Based on https://github.com/mobxjs/mobx/discussions/3013
 *
 * @param initial Initial value
 * @param getter Dependency, a function of observables
 * @param wait Debounce time in ms
 */
export function debouncedObservable<T>(initial: T, getter: () => T, wait: number) {
  const boxed = observable.box<T>(initial, {deep: false});
  const debounced = debounce(action((value: T) => boxed.set(value)), wait);

  const dispose = reaction(getter, debounced);

  return {
    dispose,
    observable: boxed
  };
}
