/**
 * Converts a string to a legal filename (without extension) that is also human
 * readable.
 *
 * @param value
 */
export function forFilename(value: string): string {
  return value
    // literal replacements
    .replace(/&/g, ' and ')
    .replace(/mr\./gi, 'mr')
    .replace(/ms\./gi, 'ms')
    .replace(/mrs\./gi, 'mrs')
    .replace(/dr\./gi, 'dr')
    .replace(/\./g, ' dot ')

    // normalize and clean up
    .toLocaleLowerCase()
    .trim()

    // collapse underscores into one space
    .replace(/_+/g, ' ')

    // collapse whitespace into one hyphen
    .replace(/\s+/g, '-')

    // remove non-hyphen punctuation
    // regex based on https://stackoverflow.com/a/65052998/1865262
    .replace(/[^\p{L}\p{M}0-9@-]+/gu, '')

    // hyphen clean up
    .replace(/-+/g, '-') // collapse hyphen runs into 1
    .replace(/^-+/, '') // clip leading
    .replace(/-+$/, ''); // clip trailing
}
