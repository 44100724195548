/**
 * Create an array mapper that uses its previous output when input hasn't
 * changed. It's assumed that the `map` parameter is expensive so the goal is
 * to minimize calls to it, within reason.
 *
 * @param map Pure function that receives one element of the array and
 * returns a result.
 */
export function createMemoizedMapper<InputType, ResultType>(map: (i: InputType) => ResultType) {
  let lastInputs: Array<InputType> = [];
  let lastResults: Array<ResultType> = [];

  return function memoizedMapper(inputs: Array<InputType>): Array<ResultType> {
    const nextResults: Array<ResultType> = [];

    for (let i = 0; i < inputs.length; i++) {
      const currentInput = inputs[i];
      const lastInput = lastInputs[i];

      nextResults.push(
        currentInput === lastInput ? lastResults[i] : map(currentInput)
      );
    }

    lastInputs = inputs;
    lastResults = nextResults;

    return nextResults;
  };
}
