import Honeybadger from '@honeybadger-io/js';

// https://docs.honeybadger.io/lib/javascript

Honeybadger.configure({
  apiKey: process.env.REACT_APP_HONEYBADGER_KEY,
  environment: process.env.NODE_ENV,
  revision: process.env.REACT_APP_REVISION,
});

/**
 * When true, errors *may* be reported to honeybadger.
 * When false, errors are not reported.
 */
let reportToHoneybadger = false;

Honeybadger.beforeNotify(() => {
  return reportToHoneybadger && !!process.env.REACT_APP_HONEYBADGER_KEY;
});

/**
 * Turn honeybadger reporting on/off.
 *
 * @param allow
 */
export function allowErrorReporting(allow: boolean) {
  reportToHoneybadger = allow;
}
