import React from 'react';
import './Switch.css';

type SwitchProps = {
  on: boolean;
  onChange: (on: boolean) => void;
  /** Label for the switch */
  children: React.ReactNode;
  block?: boolean;
  /** Must be unique per page */
  id: string;
  onString?: string;
  offString?: string;
  describedById?: string;
};

export default function Switch(props: SwitchProps) {
  const toggleSwitch = () => props.onChange(!props.on);

  return (
  <span
  className={`
    c-switch
    ${props.block ? 'c-switch--block' : ''}
  `}
  >
    <label
      onClick={() => toggleSwitch()}
      className="c-switch__label"
      id={props.id}
    >
      {props.children}
    </label>
    {props.block && <div className="c-switch__spacer"/>}
    <div
      aria-checked={props.on}
      role="checkbox"
      aria-labelledby={props.id}
      aria-describedby={props.describedById}
      tabIndex={0}
      onClick={() => toggleSwitch()}
      onKeyDown={e => e.key === ' ' && toggleSwitch()}
      className={`
        c-switch__switch-background
        ${props.on ? 'c-switch__switch-background--active' : ''}
      `}
    >
      <div
        role="presentation"
        className={`
          c-switch__switch-toggle
          ${props.on ? 'c-switch__switch-toggle--active' : ''}
        `}
      />
    </div>
    <span
      className={`
        c-switch__switch-feedback
        ${props.on ? 'c-switch__switch-feedback--active' : ''}
      `}
    >
      {props.on ?
        // Is it On
        props.onString ? props.onString : 'On' :
        // is it Off
        props.offString ? props.offString : 'Off'}
    </span>
  </span>
)};
