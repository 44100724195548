import './PageIndicator.css';

/**
 * All buttons have these props.
 */
type PageIndicatorProps = {
  count: number;
  currentIndex: number;
  onClick: (i: number) => void;
}

export default function PageIndicator(props: PageIndicatorProps) {

  const handleOnClick = (index: number) => {
    props.onClick(index);
  }

  return (
    <div className="c-page-indicator">
      {Array.from({length: props.count},
        (_, i) =>
          <button
            key={i}
            onClick={() => handleOnClick(i)}
            className={`
              c-page-indicator__dot
              ${props.currentIndex === i ?  'c-page-indicator__dot--active' : ''}
            `}
          >
            <span tabIndex={-1} className="c-page-indicator__dot-label">Step&nbsp;</span>{i + 1}
          </button>)}
    </div>
)};
