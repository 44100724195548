import 'mobx-react-lite/batchingForReactDom';
import { configure } from 'mobx';

configure({
  // These next 4 settings are set as per the mobx 5 => 6 migration guide
  // https://mobx.js.org/migrating-from-4-or-5.html
  enforceActions: 'observed',
  computedRequiresReaction: true,
  reactionRequiresObservable: true,
  observableRequiresReaction: true,
});
