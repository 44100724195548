import { getObject, setObject } from '../localstorage';
import { ComicWriterSettings } from './types';

type SettingsUpdateListener = (newSettings: ComicWriterSettings) => void;
let listeners: Array<SettingsUpdateListener> = [];

const STORAGE_KEY = 'settings';

/**
 * Get current settings.
 */
export function get(): ComicWriterSettings {
  return {
    // Spread default settings first to ensure every setting has a value,
    // regardless of what is stored.
    ...defaultSettings(),
    ...getObject(STORAGE_KEY, defaultSettings),
  };
}

/**
 * Ensure settings in storage have same properties as the latest settings in
 * code.
 */
export function migrate(): void {
  upsert({
    ...defaultSettings(),
    ...get()
  });
}

/**
 * Set new settings values, updating existing settings if any.
 *
 * @param settings
 */
export function upsert(settings: Partial<ComicWriterSettings>): void {
  set({
    ...get(),
    ...settings,
  })
}

/**
 * Set new settings values, overwriting existing settings, if any.
 *
 * @param settings
 */
export function set(settings: ComicWriterSettings): void {
  setObject(STORAGE_KEY, settings);

  for (const listener of listeners) {
    listener(settings);
  }
}

export function onSettingsChange(listener: SettingsUpdateListener): () => void {
  listeners = listeners.concat(listener);

  return () => {
    listeners = listeners.filter(fn => fn !== listener);
  };
}

function defaultSettings(): ComicWriterSettings {
  return {
    reportErrors: false,
    boldLetteringExportStyle: 'bold',
    browserSpellcheck: false,
  };
}
