import React from 'react';
import './Modal.css';

import AriaModal from 'react-aria-modal';

type ModalProps = {
  title: string;
  closeButtonOnClick: () => void;
  children: React.ReactNode;
  /**
   * Optional CSS selector of the interactible element to auto-focus after modal
   * is shown. Defaults to the modal's close button.
   */
  initialFocus?: string;
};

export default function Modal(props: ModalProps) {
  const closeButtonId = 'close-modal';
  const initialFocus = props.initialFocus || `#${closeButtonId}`;

  return (
    <AriaModal
      titleText={props.title}
      onExit={props.closeButtonOnClick}
      initialFocus={initialFocus}
      applicationNode={document.getElementById('app') as HTMLElement}
      underlayClass="c-modal"
      dialogClass="c-modal__dialouge"
    >
      <section className="c-modal__body">
        <h2 className="c-modal__title">{props.title}</h2>
        <div className="c-modal__content">
          {props.children}
        </div>
        <div className="c-modal__close">
          <button className="c-modal__close-button" id={closeButtonId} onClick={props.closeButtonOnClick}>
            <span className="c-modal__close-button__description">Close &nbsp;</span>×
          </button>
        </div>
      </section>
    </AriaModal>
  );
}
