import { EditorCommandName } from './editor/types'

interface Hotkey {
  name: string;
  hotkey: Array<string>;
  inToolbar: boolean;
  command: EditorCommandName;
}

export const Hotkeys: Array<Hotkey> = [
  {
    name: 'Page',
    hotkey: ['Alt', '1'],
    inToolbar: true,
    command: 'insertPage'
  },
  {
    name: 'Panel',
    hotkey: ['Alt', '2'],
    inToolbar: true,
    command: 'insertPanel'
  },
  {
    name: 'Balloon',
    hotkey: ['Alt', '3'],
    inToolbar: true,
    command: 'dialogue'
  },
  {
    name: 'Caption',
    hotkey: ['Alt', '4'],
    inToolbar: true,
    command: 'caption'
  },
  {
    name: 'SFX',
    hotkey: ['Alt', '5'],
    inToolbar: true,
    command: 'sfx'
  },
  {
    name: 'Bold',
    hotkey: ['Meta','B'],
    inToolbar: true,
    command: 'bold'
  },
]
