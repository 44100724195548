import {
  fileSave,
  FileSystemHandle,
} from 'browser-fs-access';
import { saveAs } from 'file-saver';

import {
  dialogSuccess,
  dialogCancel,
  isCancel,
  isGestureError,
  FileDialogOutcome,
} from './dialog-outcome';

// This is copy/pasted out of browser-nativefs, except for `handle`
export interface SaveFileOptions {
  /** Suggested file name */
  fileName?: string;
  /** Suggested file extensions */
  extensions?: string[];
  /** File type description */
  description?: string;
  /**
   * Existing file handle.
   *
   * - If included the operation will be Save.
   * - If omitted the operation will be Save As.
   */
  handle?: FileSystemHandle;
}

export type SaveFileOutcome = FileDialogOutcome<FileSystemHandle | undefined>;

/**
 * Save an arbitrary file.
 */
export async function saveFile(blob: Blob, options: SaveFileOptions = {}): Promise<SaveFileOutcome> {
  try {
    const { handle: existingHandle, ...fileSaveOptions } = options;
    const handle = await fileSave(blob, fileSaveOptions, existingHandle);

    return dialogSuccess(handle);
  } catch (error) {
    if (isCancel(error)) {
      return dialogCancel();
    } else if (isGestureError(error)) {
      // Issue 72 - try legacy api as last resort
      return legacySaveFile(blob, options);
    } else {
      throw error;
    }
  }
}

async function legacySaveFile(blob: Blob, options: SaveFileOptions = {}): Promise<SaveFileOutcome> {
  saveAs(blob, options.fileName);

  // There's no way to determine if user downloaded or canceled with legacy api
  // so ¯\_(ツ)_/¯
  return dialogSuccess(undefined);
}
