import React from 'react';
import Honeybadger from '@honeybadger-io/js';

// https://docs.honeybadger.io/lib/javascript/integration/react.html
import HoneybadgerErrorBoundary from '@honeybadger-io/react';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export default function ErrorBoundary(props: ErrorBoundaryProps) {
  return (
    <HoneybadgerErrorBoundary honeybadger={Honeybadger}>
      {props.children}
    </HoneybadgerErrorBoundary>
  );
}