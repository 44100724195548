/*
NOTE:

These values are used by CodeMirror as css classes.
Keep these in sync with CodeMirror.css
*/

/**
 * Gutter id that doesn't actually show anything but prevents CM's from
 * collapsing the gutter. When there isn't a useful gutter to show, set this
 * gutter id.
 */
export const EMPTY_GUTTER = 'empty-gutter';

/**
 * Id for word counts.
 */
export const WORD_COUNTS_GUTTER = 'word-counts';
