import { FileSystemHandle } from '../file-system';

/**
 * File extension of CW script files. Note: No leading dot.
 */
export const SCRIPT_EXTENSION = 'cwscript';
/**
 * File extension of CW script files when using legacy file system api.
 * Note: No leading dot.
 */
export const LEGACY_SCRIPT_EXTENSION = 'json';

export const SCRIPT_MIME_TYPE = 'application/json';
export const SCRIPT_DESCRIPTION = 'ComicWriter script';
export const DEFAULT_ROOT_FILENAME = 'untitled';

type ScriptFileContent = ScriptFileContentV1;

type ScriptFileContentV1 = {
  source: string;
  version: number;
}

export class ScriptFile implements ScriptFileContent {
  source: string;
  /** What version of the cwscript format this conforms to */
  version: number;
  handle?: FileSystemHandle;

  constructor() {
    this.source = '';
    this.version = 1;
  }

  static async fromFile(file: File) {
    return await file.text()
      .then(text => parseScriptFileContent(text))
      .then(fileContent => {
        const script = new ScriptFile();
        script.source = fileContent.source;
        script.version = fileContent.version;

        if ('handle' in file) {
          const withHandle = file as { handle: FileSystemHandle };
          if (withHandle.handle) {
            script.handle = withHandle.handle;
          }
        }

        return script;
      });
  }

  /**
   * Creates a copy of this script. Only the data is copied, i.e. not the
   * file system handle.
   */
  copy(): ScriptFile {
    const copy = new ScriptFile();

    copy.source = this.source;
    copy.version = this.version;
    // Don't copy handle because it's a reference to the file system and it
    // must be different for the copied script to be truly separated.
    // copy.handle = this.handle;

    return copy;
  }

  toJson(): ScriptFileContent {
    return {
      source: this.source,
      version: this.version,
    };
  }

  toBlob() {
    const jsonString = JSON.stringify(this.toJson(), null, 2);
    return new Blob([jsonString], {
      type: SCRIPT_MIME_TYPE
    });
  }
}

export function parseScriptFileContent(data: string): ScriptFileContent {
  return migrateFileContent(JSON.parse(data));
}

/**
 * Apply incremental transforms to script content so if all validation passes,
 * we have script content matching the schema of the latest schema of
 * ScriptFileContent.
 */
function migrateFileContent(content: unknown): ScriptFileContent {
  return objectToV1(unknownToObject(content));

  function unknownToObject(content: unknown): { [key: string]: unknown } {
    if (content && typeof content === 'object') {
      return content as {};
    } else {
      throw new Error('File content is not an object');
    }
  }

  function objectToV1(obj: { [key: string]: unknown }): ScriptFileContentV1 {
    if (isString(obj, 'source')) {
      // version is optional
      const version = isNumber(obj, 'version')
        ? obj.version as number
        : 1;

      return {
        ...obj,
        source: obj.source as string,
        version
      };
    } else {
      throw new Error('File content does not have source string');
    }
  }

  function isString(obj: { [key: string]: unknown }, property: string): boolean {
    return typeof obj[property] === 'string';
  }

  function isNumber(obj: { [key: string]: unknown }, property: string): boolean {
    return typeof obj[property] === 'number';
  }
}
