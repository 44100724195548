/**
 * Promise that resolves when window load event is fired or asap if the load
 * event has already been fired.
 */
export const domReady = new Promise<void>(resolve => {
  if (document.readyState === 'complete') {
    resolve();
  } else {
    window.addEventListener('load', () => resolve(), { once: true });
  }
});
