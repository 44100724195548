import { Editor } from 'codemirror';
import { useContext, useEffect, EffectCallback } from 'react';
import { CodeMirrorContext } from '../context/code-mirror-context';

type CleanUp = ReturnType<EffectCallback>;
type CodeMirrorEffectCallback = (editor: Editor) => CleanUp;

export function useCodeMirror(effect: CodeMirrorEffectCallback): void {
  const api = useContext(CodeMirrorContext);
  useEffect(() => {
    if (api) {
      return effect(api.editor);
    }
  }, [api, effect]);
}
