import React from 'react';
import './ToolBar.css';

export default function ToolBar({ children }: React.PropsWithChildren<{}>) {
  const wrappedChildren = React.Children.map(children, (node, index) =>
    isReactFragment(node) ? <ToolbarSpacer key={index} /> :
      !!node ? <ToolbarItem key={index}>{node}</ToolbarItem> :
      null
  );

  return (
    <div className="c-tool-bar">
      {wrappedChildren}
    </div>
  );
}

function isReactFragment(node: React.ReactNode): boolean {
  return typeof node == 'object'
    && node != null
    && 'type' in node
    && node.type === React.Fragment;
}

function ToolbarItem({ children }: React.PropsWithChildren<{}>) {
  return (
    <div className="c-tool-bar__item">
      {children}
    </div>
  );
}

function ToolbarSpacer() {
  return (
    <div className="c-tool-bar__spacer" />
  );
}