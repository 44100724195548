import React, { useState } from "react";
import Modal from "../../../components/modal/Modal";
import Button from "../../../components/button/Button";
import Pager from "../../../components/pager/Pager";
import ToolBar from "../../../components/tool-bar/ToolBar";
import PageIndicator from "../../../components/page-indicator/PageIndicator";

import { About, ErrorReporting, Learn } from "./Steps";

import Logo from "./logo192.png";

import "./OnBoarding.css";

export interface OnBoardingProps {
  /** Invoked when the onboarding modal wants to close. */
  onClose: () => void;
}

/**
 * Quick intro and setup sequence for first time users.
 */
export const OnBoarding = (props: OnBoardingProps) => {
  const [index, setIndex] = useState(0);

  const steps = [
    <About key="about" />,
    <ErrorReporting key="error-reporting" />,
    <Learn key="learn" />,
  ];

  const hasPrevious = index > 0;
  const hasNext = index < steps.length - 1;
  const isLast = index === steps.length - 1;

  return (
    <Modal title="Welcome Aboard!" closeButtonOnClick={props.onClose}>
      <StaticSizer>
        <div className="c-onboarding__image">
          <img src={Logo} alt="Logo" width={96} height={96} />
        </div>
      <Pager showChildAtIndex={index}>{steps}</Pager>
      </StaticSizer>

      <ToolBar>
        <></>
        <PageIndicator
          count={steps.length}
          currentIndex={index}
          onClick={(i) => setIndex(i)}
        />
        <></>
      </ToolBar>

      <div className="c-onboarding__button-container">
        <ToolBar>
          {hasPrevious && (
            <Button onClick={() => setIndex((index) => index - 1)}>
              Previous
            </Button>
          )}
          <React.Fragment />
          {hasNext ? (
            <Button primary onClick={() => setIndex((index) => index + 1)}>
              Next
            </Button>
          ) : isLast ? (
            <Button primary onClick={props.onClose}>
              Start Writing Comics
            </Button>
          ) : null}
        </ToolBar>
      </div>
    </Modal>
  );
};

// Keep every step the same size. If step content changes this should be updated.
function StaticSizer(props: { children: React.ReactNode }) {
  return <div style={{ width: 500, height: 279 }}>{props.children}</div>;
}
