import { Token, Editor } from 'codemirror';
import {
  LETTERING_SUBJECT,
  LETTERING_MODIFIER,
  LETTERING_CONTENT
} from '../../mode/token';

export function findSubject(tokens: Array<Token>): Token | null {
  return findByType(tokens, LETTERING_SUBJECT);
}

export function findModifier(tokens: Array<Token>): Token | null {
  return findByType(tokens, LETTERING_MODIFIER);
}

function findByType(tokens: Array<Token>, type: string): Token | null {
  return tokens
    .find(token => token.type?.includes(type)) || null;
}

export function findContent(tokens: Array<Token>): Array<Token> {
  return tokens
    .filter(token => token.type?.includes(LETTERING_CONTENT));
}


/**
 * Given a presumably adjacent set of tokens, select all their text.
 *
 * @param cm
 * @param line
 * @param tokens
 */
export function selectTokens(cm: Editor, line: number, ...tokens: Array<CodeMirror.Token>) {
  const { start, end } = characterRange(...tokens);

  cm.setSelection(
    { line, ch: start },
    { line, ch: end }
  );
}

function characterRange(...tokens: Array<CodeMirror.Token>) {
  return {
    start: Math.min(...tokens.map(token => token.start)),
    end: Math.max(...tokens.map(token => token.end))
  };
}
