import CodeMirror from 'codemirror';
import { useCallback, } from 'react';
import { useCodeMirror } from '../hooks/use-code-mirror';

export function BrowserSpellcheck() {
  useCodeMirror(useCallback(editor => {
    // Start of hack for #64 ///////////////////////////////////
    function pollInputSelection(cm: CodeMirror.Doc) {
      // This uses CM internals so types be damned
      (cm as any).display.input.pollSelection();
    }

    editor.on('contextmenu', pollInputSelection);
    // End of hack for #64 ///////////////////////////////////

    editor.setOption('spellcheck', true);

    return () => {
      editor.setOption('spellcheck', false);
      editor.off('contextmenu', pollInputSelection);
    };
  }, []));

  return null;
}
