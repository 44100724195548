import toast from 'react-hot-toast';
import { VersionUpdate } from './components/version-update/VersionUpdate';

export function notifyError(message: string) {
  toast.error(message);
}

export function notifySuccess(message: string) {
  toast.success(message);
}

export function notifyVersionUpdate(newVersion: string) {
  toast(<VersionUpdate version={newVersion} />, {
    duration: 5 * 1000
  });
}

interface AsyncNotifierOptions {
  /** Message in the toast */
  message: string;
  /** How many ms that the toast will be shown before auto-dismissing */
  maxDuration: number;
  /** How many ms between showing toast and starting the operation. */
  delay: number;
}

/**
 * Create function to show a toast while some async operation is running.
 */
function createAsyncNotifier(options: AsyncNotifierOptions) {
  return function notifyAsync<T>(operation: () => Promise<T>): Promise<T> {
    const toastId = toast(options.message, {
      duration: options.maxDuration
    });

    return new Promise<void>(resolve => {
      setTimeout(() => resolve(), options.delay);
    })
      .then(() => operation())
      .finally(() => toast.dismiss(toastId));
  };
}

export const notifyCreatePdf = createAsyncNotifier({
  message: 'Creating PDF...',
  maxDuration: 30 * 1000,
  delay: 250
});

export const notifyCreateDocx = createAsyncNotifier({
  message: 'Creating Word Doc...',
  maxDuration: 30 * 1000,
  delay: 250
});
