import { openFile } from './open-file';
import { FileDialogOutcome, dialogSuccess } from './dialog-outcome';

import {
  ScriptFile,
  SCRIPT_EXTENSION,
  LEGACY_SCRIPT_EXTENSION,
  SCRIPT_DESCRIPTION,
} from './script-file';

export type OpenScriptOutcome = FileDialogOutcome<ScriptFile>;

export async function openScript(): Promise<OpenScriptOutcome> {
  const outcome = await openFile({
    extensions: [`.${SCRIPT_EXTENSION}`, `.${LEGACY_SCRIPT_EXTENSION}`],
    description: SCRIPT_DESCRIPTION
  });

  if (outcome.type === 'success') {
    return dialogSuccess(await ScriptFile.fromFile(outcome.result));
  } else {
    return outcome;
  }
}