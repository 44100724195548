import { useState, useRef, useEffect } from 'react';
import { Hotkey } from '../hotkey/Hotkey'
import { useOnClickOutside } from './use-on-click-outside';
import {
  isKeyCombo,
} from '../../util/keyboard';

import './Menu.css';

type Divider = {
  type: 'divider'
}

type ChildItemClickHandler = () => void;
export type ChildItem = {
  type: 'item',
  menuName: string,
  hotkey?: Array<string>,
  onClick: ChildItemClickHandler
}

type MenuChild = ChildItem | Divider;

export type TopLevelMenu = {
  menuName: string,
  menuChildren: Array<MenuChild>
};

type MenuProps = {
  menuData: Array<TopLevelMenu>
};

const Menu = (props: MenuProps) => {
  const menuRef = useRef<HTMLElement>(null);
  const [isOpen, setOpen] = useState(false);
  // ID can change regardless of open or close state. Used to check what menu
  // the mouse is over.
  const [currentId, setCurrentId] = useState(0)
  useOnClickOutside(menuRef, () => setOpen(false));

  // close menu when esc is pressed
  useEffect(() => {
    if (isOpen) {
      const escListener = (event: KeyboardEvent) => {
        if (isKeyCombo(['Esc'])(event)) {
          setOpen(false);
        }
      };

      document.addEventListener('keydown', escListener);
      return () => document.removeEventListener('keydown', escListener);
    } else {
      return;
    }
  }, [isOpen]);

  const handleMenuClick = (cb: ChildItemClickHandler) => () => {
    cb();
    setOpen(false);
    return false;
  }

  return (
    <nav
      className="c-menu"
      ref={menuRef}
      role="menubar"
    >
      {props.menuData.map((a, i, n) =>
        <div
          key={i}
          className="c-menu__item"
        >
          <button
            tabIndex={0}
            className={`
              c-menu__item-button
              ${isOpen && currentId === i ? 'c-menu__item-button--active':''}`}
            role="menuitem"
            aria-haspopup="true"
            id={a.menuName}
            onClick={() => setOpen(!isOpen)}
            onMouseEnter={() => setCurrentId(i)}
            onFocus={() => setCurrentId(i)}
          >
            {a.menuName}
          </button>
          {isOpen && currentId === i &&
            <ul
              className="c-menu__dropdown"
              role="menu"
              aria-labelledby={a.menuName}
            >
              {a.menuChildren.map((b, j, m) => {
                return b.type === 'divider' ?
                  <hr key={j} className="c-menu__divider"/> :
                  <li key={j} className="c-menu__dropdown-item">
                    <button
                      className="c-menu__button"
                      onClick={handleMenuClick(b.onClick)}
                      role="menuitem"
                      onBlur={() => i === n.length - 1 && j === m.length - 1 && setOpen(false)}
                    >
                      <span className="c-menu__button-text">{b.menuName}</span>
                      {b.hotkey && <span className="c-menu__hotkey"><Hotkey keyCombo={b.hotkey}/></span>}
                    </button>
                  </li>
                })}
          </ul>}
        </div>
      )}
    </nav>
  );
}

export { Menu };
