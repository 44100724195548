import React from 'react';

type PagerProps = {
  children: React.ReactNode;
  showChildAtIndex: number;
};

export default function Pager(props: PagerProps) {
  return (
    <>
      {React.Children.toArray(props.children).filter((_, i) => i === props.showChildAtIndex)}
    </>
  )
};
