import { useCallback, useMemo } from 'react';
import { create } from './panel-count-manager';
import { useStore } from '../../../../store';
import { useCodeMirror } from '../hooks/use-code-mirror';
import { autorun } from 'mobx';

export function PanelCounts() {
  const { script } = useStore();
  const manager = useMemo(create, [create]);

  // update panel count widgets when counts change
  useCodeMirror(useCallback(editor => {
    const dispose = autorun(() => manager.update(editor, script.panelCounts));
    return () => {
      dispose();
      manager.clear();
    };
  }, [script, manager]));

  return null;
}
