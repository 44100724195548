import React from 'react';
import Modal from '../../../components/modal/Modal';
import Button from '../../../components/button/Button';
import ToolBar from '../../../components/tool-bar/ToolBar';
import { version, gitHash } from '../../../util/version';
import * as staticSiteUrls from '../../../util/static-site-urls';
import * as socialUrls from '../../../util/social-urls';
import { Link } from '../../../components/link/Link';

import './About.css';

export interface AboutProps {
  onClose: () => void;
}

export function About(props: AboutProps) {
  return (
    <Modal
      title="About ComicWriter"
      closeButtonOnClick={props.onClose}
    >
      <h3 className="c-about__title">ComicWriter<div className="u-font-size--saya">{version} ({gitHash})</div></h3>
      <ul>
        <li><Link url={staticSiteUrls.releaseNotesUrl(version)} newTab>Release Notes</Link></li>
        <li><Link url={staticSiteUrls.privacyUrl} newTab>Privacy Policy</Link></li>
        <li><Link url={staticSiteUrls.termsUrl} newTab>Terms</Link></li>
      </ul>
      <ul>
        <li><Link url={socialUrls.serviceDesk}>Email the project team</Link></li>
        <li><Link url={socialUrls.twitter} newTab>Follow us on Twitter</Link></li>
      </ul>

      <ToolBar>
        {/* This is here to right align the button in the toolbar */}
        <React.Fragment />
        <Button primary onClick={props.onClose}>Close</Button>
      </ToolBar>
    </Modal>
  );
}
