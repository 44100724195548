const db = window.localStorage;

/**
 * Prefix for CW-specific storage keys to prevent collisions with whatever else
 * could be out there, which is probably nothing, but just to be safe.
 */
const PREFIX = 'comic-writer.';

/**
 * Get an object out of localstorage.
 */
export const getObject = getter(PREFIX, raw => JSON.parse(raw));

/**
 * Set an object in localstorage.
 */
export const setObject = setter(PREFIX, (value: object) => JSON.stringify(value));

/**
 * Get a string out of localstorage.
 */
export const getString = getter(PREFIX, raw => raw);

/**
 * Set a string in localstorage.
 */
export const setString = setter(PREFIX, (value: string) => value);

/**
 * Gets a value from localstorage.
 *
 * @param key - Key to get
 * @param fallback - Because a localstorage key/value can disappear at any time,
 * this will be used to return a default value if the key does not exist.
 */
type LocalstorageGetter<ValueType> = (key: string, fallback: () => ValueType) => ValueType;

/**
 * Create a localstorage getter.
 *
 * @param prefix - Prefix to apply to key
 * @param parse - Parse raw value from localstorage into its in-code type.
 */
function getter<ValueType>(prefix: string, parse: (raw: string) => ValueType): LocalstorageGetter<ValueType> {
  return (key: string, fallback: () => ValueType) => {
    const value = db.getItem(prefix + key);

    if (value != null) {
      try {
        return parse(value);
      } catch (e) {
        return fallback();
      }
    } else {
      return fallback();
    }
  };
}

/**
 * Sets a value in localstorage.
 *
 * @param key - Key to set
 * @param value - Value to set
 */
type LocalstorageSetter<ValueType> = (key: string, value: ValueType) => void;

/**
 * Create a localstorage setter.
 *
 * @param prefix - Prefix to apply to the key.
 * @param stringify - Convert value to a string
 */
function setter<ValueType>(prefix: string, stringify: (value: ValueType) => string): LocalstorageSetter<ValueType> {
  return (key: string, value: ValueType) => {
    db.setItem(prefix + key, stringify(value));
  };
}