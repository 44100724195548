import './configure-mobx';
import { ScriptStore } from '../script';
import { EditorStore } from '../editor';
import { MenuStore } from '../menu';
import { ModalState } from '../modals';
import { settingsStore, SettingsStore } from '../settings';
import { InsightsStore } from '../insights';

export * from './use-store';

export interface RootStore {
  script: ScriptStore;
  editor: EditorStore;
  menu: MenuStore;
  modals: ModalState;
  settings: SettingsStore;
  insights: InsightsStore;
}

export function createStore(): RootStore {
  const settings = settingsStore;
  const modals = new ModalState();
  const script = new ScriptStore();
  const insights = new InsightsStore(script);
  const editor = new EditorStore(script, modals, settings, insights);
  const menu = new MenuStore(editor);

  const rootStore = {
    script,
    editor,
    menu,
    modals,
    settings,
    insights,
  };

  if (process.env.NODE_ENV !== 'production') {
    exposeGlobalHooks(rootStore);
  }

  return rootStore;
}

function exposeGlobalHooks(store: RootStore): void {
  const win = window as unknown as {
    openScript: (source: string) => void;
  };

  // add hook so tests can preload a script
  win.openScript = (source: string) => {
    if (typeof typeof source !== 'string') {
      throw new Error('Signature is: openScript(source: string)');
    }
    store.editor.replaceScriptWithOpened(source);
  }
}
