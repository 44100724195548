import { ensureCursorAtLineStart, chain, focus } from './command-helpers';

/**
 * Insert a page.
 *
 * @param cm
 */
export const insertPage = chain([
  focus,
  cm => ensureCursorAtLineStart(cm, '+insertPage'),
  cm => cm.replaceRange('page\n', cm.getCursor(), undefined, '+insertPage'),
]);
