import React, { useRef, useEffect } from 'react';
import { OutlineItemSelectionEvent, CenteringRequestEvent } from '../../types';
import { WithReactChildren } from '../../../components/types';
import { useNeedsScrollCallback } from './use-intersection-observer';

import './OutlineItem.css'

type Props = {
  id: string;
  /** Zero-based line number that this item corresponds to */
  lineNumber: number;
  /** Is this item the "current" item in the editor? */
  current: boolean;
  onSelection: (event: OutlineItemSelectionEvent) => void;
  onCenteringRequest: (event: CenteringRequestEvent) => void;
} & WithReactChildren;

export const OutlineItem = (props: Props) => {
  const { current, onCenteringRequest } = props;

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    props.onSelection({ lineNumber: props.lineNumber });
  };

  const needsScroll = useRef(false);
  const ref = useNeedsScrollCallback<HTMLLIElement>(
    needs => needsScroll.current = needs
  );

  useEffect(() => {
    if (current && needsScroll.current && ref.current) {
      onCenteringRequest({
        element: ref.current
      });
    }
  }, [current, onCenteringRequest, ref]);

  return (
    <li
      ref={ref}
      tabIndex={-1}
      className={`
        c-outline-item
        ${current ? 'c-outline-item--current' : ''}
      `}
    >
      <button
        tabIndex={0}
        type="button"
        onClick={handleClick}
        className="c-outline-item__button"
      >
        {props.children}
      </button>
    </li>
  );
}
