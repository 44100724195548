import { Link } from '../../../components/link/Link';
import { releaseNotesUrl } from '../../../util/static-site-urls';

type Props = {
  version: string;
};

export function VersionUpdate(props: Props) {
  const url = releaseNotesUrl(props.version);

  return (
    <span>
      Upgraded to version {props.version}!<br/><Link url={url} newTab>Release Notes</Link>
    </span>
  );
}
