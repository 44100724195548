import * as parts from '../comic-part-types';
import { Metadata, PreSpread } from '../parser/nodes';
import * as slugify from '../util/slugify';

/** Normalized key for title metadata */
const TITLE_KEY = 'title';
/** Normalized key for issue metadata */
const ISSUE_KEY = 'issue';
/** Normalized key for by metadata */
const BY_KEY = 'by';
/** Default title value */
const DEFAULT_TITLE = 'Untitled';

/**
 * Wrapper around known metadata entries.
 *
 * Known metadata affect the script/exports in various ways.
 */
export class KnownMetadata {
  private source: Map<string, string>;

  constructor(pairs: Array<[string, string]>) {
    this.source = new Map<string, string>(
      pairs.map(([key, value]) => [normalizeKey(key), value])
    );

    if (!this.source.has(TITLE_KEY)) {
      this.source.set(TITLE_KEY, DEFAULT_TITLE);
    }
  }

  static create(preSpread: Array<PreSpread>) {
    const pairs = preSpread
      .filter((node): node is Metadata => node.type === parts.METADATA)
      .map<[string, string]>((metadata) => [metadata.name, metadata.value]);

    return new KnownMetadata(pairs);
  }

  get hasTitle() {
    return this.source.has(TITLE_KEY);
  }

  get hasIssue() {
    return this.source.has(ISSUE_KEY);
  }

  get hasBy() {
    return this.source.has(BY_KEY);
  }

  get title() {
    return this.source.get(TITLE_KEY) || '';
  }

  get issue() {
    return this.source.get(ISSUE_KEY) || '';
  }

  get by() {
    return this.source.get(BY_KEY) || '';
  }

  get headerText(): string | null {
    return headerText(this.title, this.issue, this.by);
  }

  get suggestedRootFilename(): string {
    return suggestFilename(this.title, this.issue);
  }
}

function normalizeKey(key: string): string {
  return key.toLocaleLowerCase();
}

function suggestFilename(title: string, issue: string | undefined) {
  return [
    slugify.forFilename(title),
    issue
  ]
    .filter(part => part)
    .join('-');
}

/**
 * Tells if node is a metadata node that is used in the comic export's title
 * heading.
 */
export function isComicTitleMetadata(node: PreSpread): boolean {
  return (
    node.type === parts.METADATA &&
    [TITLE_KEY, ISSUE_KEY, BY_KEY].includes(node.name.toLocaleLowerCase())
  );
}

/**
 * String to put in export's header at the top of every page.
 */
export function headerText(title = '', issue = '', by = '') {
  let text = '';

  if (title) {
    text = title;

    if (issue) {
      text += ` #${issue}`;
    }
  }

  if (by) {
    if (text) {
      text += ` / ${by}`;
    } else {
      text = by;
    }
  }

  return text || null;
}
