import React from 'react';
import './Button.css';

/**
 * All buttons have these props.
 */
type CommonButtonProps = {
  children: React.ReactNode;
  id?: string;
  primary?: boolean;
}

/**
 * Typical button with a click handler.
 */
type OnClickableButtonProps = {
  type?: 'button';
  onClick: () => void;
} & CommonButtonProps;

/**
 * Form submit button.
 *
 * Submit buttons do not have onClick, instead use the form's onSubmit.
 */
type FormSubmitButtonProps = {
  type: 'submit';
} & CommonButtonProps;

type ButtonProps = OnClickableButtonProps | FormSubmitButtonProps;

export default function Button(props: ButtonProps) {
  return (
    <button
      id={props.id}
      className={`
        c-button
        ${props.primary ? 'c-button--primary' : ''}
      `}
      onClick={props.type === 'submit' ? undefined : props.onClick}
      type={props.type === 'submit' ? 'submit' : 'button'}
    >
      {props.children}
    </button>
)};
