import React from 'react';
import { Link as RouterLink } from '@reach/router';

import './Link.css';

interface LinkProps {
  url: string;
  /**
   * true to open in new window.
   * false (default) to open in current window.
   */
  newTab?: boolean;
  children: React.ReactNode;
}

export function Link(props: LinkProps) {
  const anchorLink =
    props.newTab ||
    props.url.startsWith('https://') ||
    props.url.startsWith('mailto:');

  return anchorLink ? (
    <a
      href={props.url}
      target={props.newTab ? '_blank' : undefined}
      className="c-link"
      rel="noopener noreferrer"
    >
      {props.children}{props.newTab && <NewTabIndicator />}
    </a>
  ) : (
    <RouterLink
      to={props.url}
      className="c-link"
    >
      {props.children}
    </RouterLink>
  )
}

function NewTabIndicator() {
  return (
    <span>
      <span className="u-hide--visually">Link opens in new tab/window.</span>
      <span className="c-link__new-tab-icon" aria-hidden>
        {/* right arrow */}
        &#10132;
      </span>
    </span>
  );
}
